import React from "react"
import { ListGroup } from "react-bootstrap"
import { FaPlus, FaCheck, FaChevronRight } from "react-icons/fa"
import { AiOutlineClose } from "react-icons/ai"

export const UserSelector = ({
  employees,
  action,
  selected = false,
  add = true,
  text = "",
  showIcon = true,
  ownerView = false,
  canView = false,
}) => {
  // Memoize the action handler to avoid unnecessary re-renders
  const handleClick = (employee, index) => {
    if (canView && action) {
      action(employee, index)
    }
  }

  return (
    <ListGroup>
      {employees.map((employee, index) => (
        <ListGroup.Item
          key={employee.id || index} // Prefer a unique id if available
          className={ownerView ? "bg-transparent" : "mb-3 border-2"}
          style={
            ownerView ? { backgroundColor: "transparent", border: "none" } : {}
          }
        >
          <div className="d-flex align-items-center justify-content-between">
            <div
              onClick={() => handleClick(employee, index)}
              className={`d-flex align-items-center ${
                ownerView && canView ? "pointer" : ""
              }`}
            >
              <img
                src={employee.profile_image}
                alt={employee.full_name}
                width={ownerView ? 60 : 40}
                height={ownerView ? 60 : 40}
                className="rounded-circle me-2"
              />
              <div style={ownerView ? { fontSize: "1.25rem" } : {}}>
                <span
                  className={
                    ownerView && canView ? "text-decoration-underline" : ""
                  }
                >
                  {ownerView ? (
                    text
                  ) : (
                    <>
                      {employee.full_name} ({employee.employee_number})
                    </>
                  )}
                </span>
                <div className={`small ${ownerView ? "fs-6" : ""}`}>
                  {employee.position?.title}
                </div>
              </div>
            </div>
            {action && !ownerView && !employee.no_action && (
              <div
                className="pointer"
                onClick={() => action(employee, index)}
              >
                {add && showIcon ? (
                  <FaPlus />
                ) : !add && showIcon ? (
                  <AiOutlineClose />
                ) : (
                  <FaChevronRight />
                )}
              </div>
            )}
            {selected && <FaCheck />}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}
