import React from "react"

const IconWithText = ({
  Icon,
  iconClassNames = "",
  iconSize = 20,
  text,
  textClassNames = "",
}) => {
  return (
    <div className="d-flex align-items-start gap-3">
      <Icon
        className={iconClassNames}
        size={iconSize}
      />
      <p className={textClassNames + "small"}>{text}</p>
    </div>
  )
}

export default IconWithText
