import React, { useState, useEffect, useContext } from "react"
import { Button, Col, Offcanvas, Row } from "react-bootstrap"
import DashboardPageContainer from "./components/DashboardPageContainer"
import AppointmentForm from "./Forms/Appointment/AppointmentForm"
import {
  useToast,
  updateObjectState,
  useAppModal,
  useAppModal2,
} from "../utils/functions"
import {
  getDayJsDateTime,
  isSame,
  dateFormats,
  formDate,
  dateStringgToLocaleMidnight,
} from "../utils/dateTime"
import { apiRequest } from "../utils/apiRequests"
import { AppContext } from "../utils/components/AppContext"
import { IoMdSettings } from "react-icons/io"
import { FaCalendarPlus, FaChevronRight, FaPlus } from "react-icons/fa"
import "react-calendar/dist/Calendar.css" // import default styles
import Calendar from "react-calendar"
import AppointmentModal from "./components/Appointment/AppointmentModal"
import { UserSelector } from "./components/Appointment/UserSelector"
import { LoadingView } from "../utils/components"
import EmployeeSearch from "./components/EmployeeSearch"
import DayHours from "./components/Appointment/DayHour"
import "../CalendarStyles.css"
import { AppConstants } from "../utils/appConstants"
import { BsCalendar3RangeFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../utils/appRoutes"

const CREATE_APPOINTMENT = "create_apointment"
const CALENDAR_SETTINGS = "calendar_settings"
const APPOINTMENT_CALENDAR = "appointment_calendar"
const SEARCH_FOR_USER = "search_for_user"

const CalendarDisplay = () => {
  const { showToast } = useToast()
  const { showAppModal } = useAppModal()
  const { showAppModal2, closeModal } = useAppModal2()
  const [managedCalendars, setManagedCalendars] = useState([])
  const [calendarManager, setCalendarManager] = useState([])
  const [seriesFormDatas, setSeriesFormDatas] = useState({})
  const [calendar, setCalendar] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const [showOffCanvas, setShowOffCanvas] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [canvasInfo, setCanvasInfo] = useState({
    type: "",
    title: "",
    subtitle: "",
  })
  const [data, setData] = useState({
    repeat: false,
    send_email_reminder: false,
    notify_owner: false,
  })
  const [appointments, setAppointments] = useState({})
  const { contextState } = useContext(AppContext)
  const { employee, darkMode } = contextState
  const [selectedDate, setSelectedDate] = useState(new Date())

  const navigate = useNavigate()

  const [activeDate, setActiveDate] = useState(null)

  const fetchCalendars = async () => {
    const calendarId = localStorage.getItem(
      AppConstants.activeAppointmentsCalendarIdKey
    )
    if (calendarId) {
      setIsLoading2(true)
    } else {
      setIsLoading(true)
    }
    try {
      const { success, response } = await apiRequest.get(
        `/appointment/calendar${calendarId ? `?calendar_id=${calendarId}` : ""}`
      )
      if (success) {
        setCalendar(response.calendar)
        getAppointments(response.calendar.id)
        setManagedCalendars(response.managed_calendars)
        setCalendarManager(response.calendar.managers)
        setIsLoading2(false)
        setIsLoading(false)
      }
    } catch (error) {
      showToast({
        message: error,
        success: false,
      })
    } finally {
      setIsLoading(false)
      setIsLoading2(false)
    }
  }

  useEffect(() => {
    fetchCalendars()
  }, [])

  const getAppointments = async (calendar_id, date = null) => {
    setIsLoading(true)
    const queryString = date ? `?date=${date}` : ""
    const { success, response } = await apiRequest.get(
      `/appointment/${calendar_id}${queryString}`
    )
    if (success) {
      if (date) {
        updateObjectState(setAppointments, response.appointments)
        updateObjectState(setSeriesFormDatas, response.series_form_datas)
      } else {
        setAppointments(response.appointments)
        setSeriesFormDatas(response.series_form_datas)
        // setSelectedDate(getDayJsDateTime(response.selected_date).toDate());
      }
      setActiveDate(date || response.selected_date)
    }
    setIsLoading(false)
  }

  const addManager = async (vals) => {
    setIsLoading2(true)
    setShowOffCanvas(true)
    const { success, response } = await apiRequest.put(
      `/appointment/calendar/manager`,
      vals
    )
    if (success) {
      setCalendar(response.calendar)
      await fetchCalendars(calendar.id)
      showToast({
        message: response.message,
        success: true,
      })
      setCanvasInfo({
        title: `Calendar Settings`,
        subtitle: `Managers can add, edit and delete appointments from your calendar`,
        type: CALENDAR_SETTINGS,
      })
      setIsLoading2(false)
    }
  }

  const removeManager = async (managerEmployeeId) => {
    setIsLoading2(true)
    setShowOffCanvas(true)
    const { success, response } = await apiRequest.delete(
      `/appointment/calendar/manager/${managerEmployeeId}`
    )
    if (success) {
      setCalendar(response.calendar)
      await fetchCalendars(calendar.id)
      showToast({
        message: response.message,
        success: true,
      })

      setCanvasInfo({
        title: `Calendar Settings`,
        subtitle: `Managers can add, edit and delete appointments from your calendar`,
        type: CALENDAR_SETTINGS,
      })
      setIsLoading2(false)
    }
    if (!success) {
      showToast({
        message: response.message,
        success: false,
      })
      setCanvasInfo({
        title: `Calendar Settings`,
        subtitle: `Managers can add, edit and delete appointments from your calendar`,
        type: CALENDAR_SETTINGS,
      })
      setIsLoading2(false)
    }
  }

  const addAppointments = async (vals) => {
    setIsLoading(true)
    const dateStr = getDayJsDateTime(activeDate).format(dateFormats.fullDate)
    const { success, response } = await apiRequest.put(
      `/appointment/${calendar.id}`,
      vals
    )
    if (success) {
      showToast({
        message: response.message,
        success: true,
      })
      updateObjectState(setSeriesFormDatas, response.series_form_datas)
      updateObjectState(setAppointments, response.appointments)
      getAppointments(calendar.id, dateStr)
      setShowOffCanvas(false)
      resetData()
    } else {
      showToast({
        message: response.message,
        success: false,
      })
    }
    setIsLoading(false)
  }

  const handleEditData = (id, appointment) => {
    if (appointment.is_edit) {
      editAppointments(id, appointment)
    } else {
      copyAppointments(id, appointment)
    }
  }
  const editAppointments = async (id, vals) => {
    setIsLoading2(true)
    const dateStr = getDayJsDateTime(activeDate).format(dateFormats.fullDate)
    const { success, response } = await apiRequest.patch(
      `/appointment/${id}`,
      vals
    )
    if (success) {
      setShowOffCanvas(false)
      showToast({
        message: response.message,
        success: true,
      })
      getAppointments(calendar.id, dateStr)
    } else {
      showToast({
        message: response.message,
        success: false,
      })
    }
    setIsLoading2(false)
  }

  const copyAppointments = async (id, vals) => {
    // This uses the same endpoint as the create appointment
    setIsLoading2(true)
    const dateStr = getDayJsDateTime(activeDate).format(dateFormats.fullDate)
    const { success, response } = await apiRequest.put(
      `/appointment/${id}`,
      vals
    )
    if (success) {
      setShowOffCanvas(false)
      showToast({
        message: response.message,
        success: true,
      })
      getAppointments(calendar.id, dateStr)
    }
    setIsLoading2(false)
  }

  const cancelAppointment = async (isSeries = false, id) => {
    const endpointArgs = isSeries ? "?delete_series=true" : ""
    const dateStr = getDayJsDateTime(activeDate).format(dateFormats.fullDate)
    setIsLoading(true)
    try {
      const { success, response } = await apiRequest.delete(
        `/appointment/${id}${endpointArgs}`
      )
      if (success) {
        setShowOffCanvas(false)
        showToast({
          message: response.message,
          success: true,
        })
        getAppointments(calendar.id, dateStr)
      }
    } catch (error) {
      showToast({
        message: "An error occurred while canceling the appointment.",
        success: false,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectEvent = (event) => {
    setData(event)
    setShowModal(true)
  }

  const resetData = () => {
    setData({})
  }

  const onDayChange = (date) => {
    const dateStr = getDayJsDateTime(date).format(dateFormats.fullDate)
    if (!appointments[dateStr]) {
      getAppointments(calendar.id, dateStr)
    } else {
      setActiveDate(dateStr)
    }
  }

  const isCurrentEmployee = employee.id === calendar?.employee?.id
  const hasManagedCalendars = managedCalendars.length > 0

  const handleAction = async () => {
    if (hasManagedCalendars) {
      setShowOffCanvas(true)
      setCanvasInfo({
        title: `Appointment Calendar`,
        subtitle: `Tap on a user to switch to their calendar and help manage their appointments`,
        type: APPOINTMENT_CALENDAR,
      })
    }
  }

  if (isLoading) {
    return <LoadingView isLoading={isLoading} />
  } else {
    return (
      <>
        <div className="d-flex fixed justify-content-center align-items-center mb-1">
          {/* UserSelector on the far left */}
          <div className="d-flex flex-grow-1">
            <UserSelector
              employees={
                isCurrentEmployee
                  ? [employee]
                  : [calendar?.employee ?? employee]
              }
              add={false}
              text={`${
                isCurrentEmployee
                  ? "Your"
                  : `${calendar?.employee?.first_name} ${calendar?.employee?.last_name}'s`
              } Calendar`}
              showIcon={false}
              canView={
                hasManagedCalendars && (isCurrentEmployee || !isCurrentEmployee)
              }
              ownerView={true}
              action={handleAction}
            />
          </div>

          {/* Icons on the far right */}
          <div className="d-flex align-items-center justify-content-end flex-grow-1">
            <FaCalendarPlus
              className="me-4 pointer icon-link-hover text-primary"
              size={30}
              onClick={() => {
                setShowOffCanvas(true)
                setCanvasInfo({
                  title: `Create Appointment`,
                  subtitle: `Please fill in the details below.`,
                  type: CREATE_APPOINTMENT,
                })
                resetData()
              }}
            />

            {employee.id !== calendar?.employee?.id ? null : (
              <IoMdSettings
                className="me-4 icon-link-hover pointer text-primary"
                size={40}
                onClick={() => {
                  setShowOffCanvas(true)
                  setCanvasInfo({
                    title: `Calendar Settings`,
                    subtitle: `Managers can add, edit and delete appointments from your calendar`,
                    type: CALENDAR_SETTINGS,
                  })
                }}
              />
            )}
          </div>
        </div>

        <Row className={"mt-4"}>
          <Col
            xs={12}
            lg={3}
            className={"mb-4 ps-4"}
          >
            <Calendar
              defaultDate={selectedDate}
              onChange={(date) => {
                setSelectedDate(date)
                onDayChange(date)
              }}
              value={selectedDate}
              className={`${
                darkMode ? "bg-dark text-white" : "bg-white text-dark"
              }`}
              tileClassName={({ date, view }) => {
                if (formDate(date) === formDate(selectedDate)) {
                  return "bg-primary text-white" // Highlight selected date
                }
                return "bg-body primary-hover"
              }}
            />

            {calendar.can_be_external && (
              <Button
                className={"mt-5"}
                variant={"outline-primary"}
                onClick={() => {
                  // pass calendar name to next page
                  navigate(
                    `${AppRoutes.ManageExternamAppointments.paramLessPath}/${calendar.id}`,
                    {
                      state: {
                        calendarName: `${
                          isCurrentEmployee
                            ? "Your"
                            : `${calendar?.employee?.first_name} ${calendar?.employee?.last_name}'s`
                        } Calendar`,
                      },
                    }
                  )
                }}
              >
                <BsCalendar3RangeFill className={"me-2"} />
                External Appointments <FaChevronRight className={"ms-2"} />
              </Button>
            )}
          </Col>

          <Col
            xs={12}
            lg={9}
          >
            {/* DayHours component positioned on the right with scrolling */}
            <DayHours
              darkMode={darkMode}
              employee={calendar?.employee?.id}
              day={activeDate}
              items={appointments[activeDate] || {}}
              seriesFormDatas={seriesFormDatas}
              onboxLongPress={(appointment) => {
                handleSelectEvent(appointment)
              }}
            />
          </Col>
        </Row>

        <AppointmentModal
          showModal={showModal}
          setShowModal={setShowModal}
          resetData={resetData}
          data={data}
          setData={setData}
          isLoading={isLoading2}
          showAppModal={showAppModal}
          closeModal={closeModal}
          showAppModal2={showAppModal2}
          setCanvasInfo={setCanvasInfo}
          setShowOffCanvas={setShowOffCanvas}
          cancelAppointment={cancelAppointment}
        />

        <Offcanvas
          show={showOffCanvas}
          onHide={() => {
            setShowOffCanvas(false)
            resetData()
          }}
          placement={"end"}
          backdrop={"static"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{canvasInfo.title}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className={"mb-1"}>{canvasInfo.subtitle}</div>

            {canvasInfo.type === CREATE_APPOINTMENT && employee?.id && (
              <AppointmentForm
                data={data}
                setData={setData}
                isSelfCalendar={calendar.employee?.id === employee.id}
                onSubmit={(vals) => {
                  addAppointments(vals)
                }}
                handleEditData={handleEditData}
                cancelAppointment={cancelAppointment}
                contextState={contextState}
                darkMode={darkMode}
              />
            )}

            {canvasInfo.type === CALENDAR_SETTINGS && employee?.id && (
              <div>
                <div className="d-flex mb-4  justify-content-between align-items-center mt-4">
                  <p className="fw-bold mb-0">Managers</p>
                  <FaPlus
                    className="pointer icon-link-hover"
                    size={20}
                    onClick={() => {
                      resetData() // Clear current event and form
                      setShowOffCanvas(true)
                      setCanvasInfo({
                        title: `Calendar Settings`,
                        subtitle: ``,
                        type: SEARCH_FOR_USER,
                      })
                    }}
                  />
                </div>
                {calendarManager?.length > 0 ? (
                  calendarManager?.map((manager, i) => (
                    <UserSelector
                      key={i}
                      employees={[manager.manager_employee]}
                      add={false}
                      showIcon={true}
                      action={() =>
                        showAppModal({
                          size: "lg",
                          title: `Remove ${manager.manager_employee.full_name} as a manager?`,
                          centered: false,
                          component: (
                            <p className={"text-center"}>
                              Are you sure you want to Proceed?
                            </p>
                          ),
                          truthyFunction: () => {
                            removeManager(manager.manager_employee.id)
                          },
                        })
                      }
                    />
                  ))
                ) : (
                  <>
                    <p className="x-small-text text-muted">
                      No managers added yet. Tap the + icon to add a manager
                    </p>
                  </>
                )}
              </div>
            )}

            {canvasInfo.type === APPOINTMENT_CALENDAR && (
              <div>
                <p className={"mt-4 fw-bold"}>Calendars you manage</p>
                {managedCalendars.length > 0 &&
                  managedCalendars.map((cal) => {
                    const selected = cal.id === calendar.id
                    return (
                      <div
                        key={cal.id}
                        className="my-2"
                      >
                        <UserSelector
                          employees={[cal.employee]}
                          add={false}
                          showIcon={false}
                          selected={selected}
                          action={
                            selected
                              ? null
                              : async () => {
                                  localStorage.setItem(
                                    AppConstants.activeAppointmentsCalendarIdKey,
                                    cal.id
                                  )
                                  fetchCalendars()
                                  setShowOffCanvas(false)
                                }
                          }
                        />
                      </div>
                    )
                  })}

                {calendar.employee?.id !== employee.id && (
                  <div className="my-4">
                    <p className={"my-3 fw-bold"}>Switch to Your Calendar</p>
                    <UserSelector
                      employees={[employee]}
                      add={false}
                      showIcon={false}
                      action={(employee, index) => {
                        localStorage.removeItem(
                          AppConstants.activeAppointmentsCalendarIdKey
                        )
                        fetchCalendars()
                        setShowOffCanvas(false)
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            {canvasInfo.type === SEARCH_FOR_USER && (
              <div className="mt-n7">
                {" "}
                {/* Adjust the negative margin to move the div up */}
                <EmployeeSearch
                  onResultClick={(selectedEmployee) => {
                    if (selectedEmployee) {
                      showAppModal({
                        size: "lg",
                        title: `Add ${selectedEmployee?.full_name} as manager`,
                        centered: false,
                        component: (
                          <p className={"text-center"}>
                            Are you sure you want to Proceed?
                          </p>
                        ),
                        truthyFunction: () => {
                          addManager({
                            manager_employee_id: selectedEmployee.id,
                          })
                        },
                      })
                    }
                  }}
                />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    )
  }
}

function AppointmentsPage() {
  return <DashboardPageContainer PageComponent={CalendarDisplay} />
}

export default AppointmentsPage
