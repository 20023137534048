import React, { useContext, useState } from "react"
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Badge,
  CloseButton,
  Alert,
  Card,
} from "react-bootstrap"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import dayjs from "dayjs"
import { AppContext } from "../../../utils/components/AppContext"
import { formatDate, formDate } from "../../../utils/dateTime"
import { useAppModal } from "../../../utils/functions"
import { LoadingView } from "../../../utils/components"

const AppointmentSlotSelector = ({ onSubmit, calendarId, putRequest }) => {
  const { contextState } = useContext(AppContext)
  const { employee, darkMode } = contextState
  const { showAppModal } = useAppModal()

  const [selectedDates, setSelectedDates] = useState([])
  const [selectedDuration, setSelectedDuration] = useState(30) // Default duration
  const [selectedSlots, setSelectedSlots] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const generateTimeSlots = (duration) => {
    const slots = []
    let time = dayjs().startOf("day") // Start at 00:00
    const endOfDay = dayjs().endOf("day")

    while (time.isBefore(endOfDay)) {
      slots.push(time.format("HH:mm"))
      time = time.add(duration, "minute")
    }

    return slots
  }

  const handleDurationChange = (duration) => {
    setSelectedDuration(duration)
    setSelectedSlots([]) // Clear selections when duration changes
  }

  const handleSlotSelection = (slot) => {
    setSelectedSlots(
      (prev) =>
        prev.includes(slot)
          ? prev.filter((s) => s !== slot) // Deselect if already selected
          : [...prev, slot] // Add to selected slots
    )
  }

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD")
    setSelectedDates(
      (prev) =>
        prev.includes(formattedDate)
          ? prev.filter((d) => d !== formattedDate) // Deselect if already selected
          : [...prev, formattedDate] // Add to selected dates
    )
  }

  const removeDate = (date) => {
    setSelectedDates((prev) => prev.filter((d) => d !== date))
  }

  const timeSlots = generateTimeSlots(selectedDuration)

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <h3>Appointment Slots</h3>
          <p className={"mb-3"}>
            Select the dates and time slots you want to make available for
            people to book.
          </p>

          <Row className={"mt-4"}>
            {/* Left Column: Calendar */}
            <Col
              xs={12}
              lg={3}
              className={"mb-4"}
            >
              <h4 className={"mb-3"}>Select Dates</h4>
              <Calendar
                onClickDay={handleDateChange}
                className={`${
                  darkMode ? "bg-dark text-white" : "bg-white text-dark"
                }`}
                tileClassName={({ date, view }) => {
                  if (selectedDates.includes(date)) {
                    return "bg-primary text-white" // Highlight selected date
                  }
                  return "bg-body primary-hover"
                }}
              />

              <div className="mt-4">
                <h4>Selected Dates:</h4>
                {selectedDates.length > 0 ? (
                  <div className="d-flex flex-column">
                    {selectedDates.map((date, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center mb-2"
                      >
                        <Badge
                          bg="primary"
                          className="p-2"
                        >
                          {formatDate(date)}
                        </Badge>
                        <CloseButton
                          className="ms-2"
                          variant="white"
                          onClick={() => removeDate(date)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No dates selected.</p>
                )}
              </div>
            </Col>

            {/* Right Column: Slot Selection */}

            <Col
              xs={12}
              lg={9}
            >
              <Card
                body={true}
                className={"py-3"}
              >
                <h4>Select Duration</h4>
                <p className={""}>
                  This will be the duration of the appointments for the
                  timeslots you'll select below
                </p>
                <ButtonGroup className="mb-3 mt-3">
                  <Button
                    variant={
                      selectedDuration === 30 ? "primary" : "outline-primary"
                    }
                    onClick={() => handleDurationChange(30)}
                  >
                    30 mins
                  </Button>
                  <Button
                    variant={
                      selectedDuration === 60 ? "primary" : "outline-primary"
                    }
                    onClick={() => handleDurationChange(60)}
                  >
                    1 hr
                  </Button>
                  <Button
                    variant={
                      selectedDuration === 120 ? "primary" : "outline-primary"
                    }
                    onClick={() => handleDurationChange(120)}
                  >
                    2 hrs
                  </Button>
                </ButtonGroup>

                <h4 className={"mt-4"}>Select Available Timeslots</h4>
                <p>
                  These will be the start times for the appointments. The end
                  times will be calculated based on the duration you selected
                  above.
                </p>
                <div className="d-flex flex-wrap mt-4">
                  {timeSlots.map((slot, index) => (
                    <Button
                      key={index}
                      className="m-1"
                      variant={
                        selectedSlots.includes(slot)
                          ? "primary"
                          : "outline-primary"
                      }
                      onClick={() => handleSlotSelection(slot)}
                    >
                      {slot}
                    </Button>
                  ))}
                </div>

                <div className="mt-4">
                  <h6>Selected Slots:</h6>
                  {selectedSlots.length > 0 ? (
                    <div className="d-flex flex-wrap">
                      {selectedSlots.map((slot, index) => (
                        <Badge
                          key={index}
                          bg="primary"
                          className="m-1 p-2"
                        >
                          {slot}
                        </Badge>
                      ))}
                    </div>
                  ) : (
                    <p>No slots selected.</p>
                  )}
                </div>
              </Card>
            </Col>
          </Row>

          <div className="d-flex justify-content-end mt-5">
            <Button
              variant="primary"
              disabled={
                selectedDates.length === 0 || selectedSlots.length === 0
              }
              onClick={() => {
                showAppModal({
                  title: "Create Appointment Slots",
                  component: (
                    <Container>
                      <Alert
                        variant={"info"}
                        className={"mb-4"}
                      >
                        NOTE: All the time slots you selected will be applied to
                        each date, meaning all selected dates will have the same
                        timeslots
                      </Alert>
                      <Row>
                        <Col
                          xs={12}
                          lg={6}
                        >
                          <h5>Selected Dates</h5>
                          {selectedDates.map((date, index) => (
                            <p key={index}>{formatDate(date)}</p>
                          ))}
                        </Col>
                        <Col
                          xs={12}
                          lg={6}
                        >
                          <h5>Selected Slots</h5>
                          {selectedSlots.map((slot, index) => (
                            <p key={index}>{slot}</p>
                          ))}
                        </Col>
                      </Row>
                    </Container>
                  ),
                  truthyFunction: () => {
                    putRequest(
                      `/appointment/external/slots/${calendarId}`,
                      setIsLoading,
                      (response) => {
                        setSelectedDates([])
                        setSelectedSlots([])
                      },
                      {
                        dates: selectedDates,
                        slots: selectedSlots,
                        duration: selectedDuration,
                      },
                      false,
                      false
                    )
                  },
                  truthyText: "Create Appointment Slots",
                  falsyText: "Cancel",
                })
              }}
            >
              Create Appointment Slots
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default AppointmentSlotSelector
