import React, { useContext } from "react"
import { Card, Table } from "react-bootstrap"
import { AppConstants } from "../../../utils/appConstants"
import { AppContext } from "../../../utils/components/AppContext"

function OrganizationsTable({
  organizations,
  onClick,
  pagingInfo,
  extraColumns,
  noOrganizationsMsg = "No organizations to display",
}) {
  /* extraColumns is an array of objects with the following structure:
    {
      columnName: "Column Name",
      key: "keyName", used to index into the employee object
      renderComponent: (org) => { return <p>{employee.name}</p> }
    }
    if renderComponent is not provided, ignore key
   */

  const { contextState, updateContextState } = useContext(AppContext)
  const { labelsOverride } = contextState
  const startingIndex = pagingInfo
    ? (pagingInfo.current_page - 1) * pagingInfo.per_page + 1
    : 0

  extraColumns = extraColumns || []

  return (
    <div>
      {organizations.length > 0 && (
        <Table
          striped
          bordered
          hover
          responsive
        >
          <thead>
            <tr className={"fs-6"}>
              <th></th>
              <th></th>
              <th>Name</th>
              {extraColumns &&
                extraColumns.map((column, index) => (
                  <th key={index}>{column.columnName}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {organizations.map((organization, index) => (
              <tr
                key={`${organization.id}-${index}`}
                className={`align-middle small`}
              >
                <td>{pagingInfo ? index + startingIndex : index + 1}</td>
                <td className={"text-center"}>
                  <img
                    src={organization.logo || AppConstants.defaultProfileImage}
                    alt={organization.name}
                    width="30"
                    height="30"
                    className={"rounded-circle"}
                  />
                </td>
                <td
                  className={`${
                    onClick ? "pointer text-decoration-underline" : ""
                  } `}
                  onClick={onClick ? () => onClick(organization) : null}
                >
                  {organization.name}
                </td>
                {extraColumns &&
                  extraColumns.map((column, index) => (
                    <td key={index}>
                      {column.renderComponent
                        ? column.renderComponent(organization)
                        : organization[column.key]}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {organizations.length === 0 && (
        <Card
          body={true}
          className={"text-center py-4"}
        >
          <Card.Text>{noOrganizationsMsg}</Card.Text>
        </Card>
      )}
    </div>
  )
}

export default OrganizationsTable
