export class StaffPermissions {
  static VIEW_STAFF = "VIEW_STAFF"
  static MANAGE_STAFF = "MANAGE_STAFF"
  static VIEW_ORGANIZATIONS = "VIEW_ORGANIZATIONS"
  static VIEW_DETAILED_ORGANIZATION_STATS = "VIEW_DETAILED_ORGANIZATION_STATS"
  static VIEW_ORGANIZATIONS_SUBSCRIPTIONS = "VIEW_ORGANIZATIONS_SUBSCRIPTIONS"
  static MANAGE_ORGANIZATIONS_SUBSCRIPTIONS =
    "MANAGE_ORGANIZATIONS_SUBSCRIPTIONS"
  static VIEW_FINANCIALS = "VIEW_FINANCIALS"
  static MANAGE_FINANCIALS = "MANAGE_FINANCIALS"
  static VIEW_STAFF_ACTIVITIES = "VIEW_STAFF_ACTIVITIES"
  static VIEW_ORGANIZATIONS_DOCUMENTS = "VIEW_ORGANIZATIONS_DOCUMENTS"
  static MANAGE_ORGANIZATIONS_DOCUMENTS = "MANAGE_ORGANIZATIONS_DOCUMENTS"
  static VIEW_ATTENDANCE_DEVICES = "VIEW_ATTENDANCE_DEVICES"
  static MANAGE_ATTENDANCE_DEVICES = "MANAGE_ATTENDANCE_DEVICES"
  static VIEW_CONTROLS = "VIEW_CONTROLS"
  static MANAGE_CONTROLS = "MANAGE_CONTROLS"
}
