import React, { useContext, useEffect, useState } from "react"
import { Card, Form, Button, Container, Col, Row } from "react-bootstrap"
import { BsEnvelope, BsLock } from "react-icons/bs"
import { apiRequest } from "../utils/apiRequests"
import { LoadingView } from "../utils/components"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "../utils/appRoutes"
import { isEmpty, useAppModal, useToast } from "../utils/functions"
import { AppConstants } from "../utils/appConstants"
import { AppContext } from "../utils/components/AppContext"
import PageMetaTags from "./components/Shared/PageMetaTags"
import AppModal from "../utils/components/AppModal"
import { appConfigs } from "../configs"
import LogoWithText from "./components/Shared/LogoWithText"
import AppToasts from "../utils/components/AppToasts"
import AppOffCanvas from "../utils/components/AppOffCanvas"

let displaySessionToast = true

function SignIn() {
  const navigate = useNavigate()
  const { showToast } = useToast()
  const { contextState, updateContextState } = useContext(AppContext)
  const location = useLocation()
  const { showAppModal } = useAppModal()

  useEffect(() => {
    if (contextState.authenticated) {
      displaySessionToast = false
      const path = location?.state?.targetPath || AppRoutes.Home.path
      navigate(path)
    } else {
      setTimeout(() => {
        if (location?.state?.targetPath && displaySessionToast) {
          showToast({
            title: "Session Expired",
            message:
              "Your session has expired. Please sign in again to continue.",
          })
        }
      }, 1000)
    }
  }, [contextState.authenticated])

  const [data, setData] = useState({
    email: "",
    password: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const nextUrl = urlParams.get("next")

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSignIn = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const { success, response } = await apiRequest.post("/user/signin", data)
    if (success) {
      await localStorage.setItem(AppConstants.authTokenKey, response.token)

      updateContextState({
        authenticated: true,
        ...response.user_auth_params,
      })
    } else {
      showToast({
        message: response.message,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (
      contextState.authenticated &&
      nextUrl &&
      nextUrl !== AppRoutes.SignIn.path
    ) {
      navigate(nextUrl)
    } else if (contextState.authenticated) {
      navigate(AppRoutes.Home.path)
    }
  }, [contextState.authenticated])

  const formValid = () => {
    const requiredFields = ["email", "password"]
    let valid = true
    for (const field of requiredFields) {
      if (isEmpty(data[field])) {
        valid = false
        break
      }
    }
    return valid
  }

  return (
    <LoadingView
      isLoading={isLoading}
      addAppToasts={true}
      view={
        <Container fluid={true}>
          <PageMetaTags title={`Sign In`} />
          <Row className="justify-content-center align-items-center min-vh-100">
            <Col
              lg={8}
              lg={6}
              xl={4}
            >
              <Card
                className={
                  "w-f00 border-primary border-1 rounded-start shadow-lg"
                }
              >
                <Card.Header className="text-center">
                  <LogoWithText
                    text={`Sign in to your ${appConfigs.appName} account`}
                  />
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSignIn}>
                    <Form.Group
                      controlId="formBasicEmail"
                      className={"mb-4"}
                    >
                      <Form.Label>Email address</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsEnvelope />
                        </span>
                        <Form.Control
                          type="email"
                          name="email"
                          autoComplete={"email"}
                          placeholder="Enter email"
                          value={data.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="formBasicPassword"
                      className={"mb-5"}
                    >
                      <Form.Label>Password</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsLock />
                        </span>
                        <Form.Control
                          type="password"
                          name="password"
                          autoComplete={"current-password"}
                          placeholder="Password"
                          value={data.password}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={!formValid()}
                    >
                      Sign In
                    </Button>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <div className="py-4 d-flex justify-content-between align-items-center">
                    <a
                      href="/reset-password"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate(AppRoutes.PasswordReset.path)
                      }}
                    >
                      <strong>Forgot Your Password?</strong>
                    </a>
                    <a
                      href="/contact"
                      onClick={(e) => {
                        e.preventDefault()
                        showAppModal({
                          title: "Contact Us",
                          hideFooter: true,
                          component: (
                            <div>
                              <p>
                                Whether you have 10 or 100,000 employees, we
                                have a complete suite of tools to help you
                                manage your workforce efficiently.
                                <br />
                                Please reach out to us at the email below and we
                                will get back to you as soon as possible.
                              </p>
                              <h4 className={"mt-4 fw-bold text-center"}>
                                <a href={`mailto:${AppConstants.supportEmail}`}>
                                  {AppConstants.supportEmail}
                                </a>
                              </h4>
                            </div>
                          ),
                        })
                      }}
                    >
                      <strong>Need an Account?</strong>
                    </a>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <AppModal />
          <AppToasts />
        </Container>
      }
    />
  )
}

export default SignIn
