import React, { useContext, useEffect, useRef, useState } from "react"
import { Container, Row, Col, Card, Offcanvas } from "react-bootstrap"
import LeftNavbar from "./LeftNavbar"
import TopNavbar from "./TopNavbar"
import AppToasts from "../../utils/components/AppToasts"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppModal, useOffCanvas, useToast } from "../../utils/functions"
import { AppContext } from "../../utils/components/AppContext"
import { AppRoutes } from "../../utils/appRoutes"
import AppOffCanvas from "../../utils/components/AppOffCanvas"
import AppModal from "../../utils/components/AppModal"
import ThemeSwitch from "../../utils/components/ThemeSwitch"
import { apiRequest } from "../../utils/apiRequests"
import { OffCanvasContext } from "../../utils/components/OffCanvasContext"
import { ConditionalView } from "../../utils/components"

function DashboardPageContainer({
  pageComponent = null,
  PageComponent = null,
}) {
  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0)
  }, [])

  const [headerBkgColor, setHeaderBkgColor] = useState("bg-transparent")
  const [showSidebar, setShowSidebar] = useState(false)
  const leftNavbarScrollRef = useRef(null)
  const leftNavbarScrollTimeoutRef = useRef(null)

  const navigate = useNavigate()
  const { showToast } = useToast()
  const { contextState, updateContextState } = useContext(AppContext)
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext)
  const location = useLocation()
  const scrollRef = useRef(null)

  const handleScroll = () => {
    // set header background color when scroll is at top and scrolling
    if (scrollRef.current.scrollTop > 0) {
      setHeaderBkgColor("bg-body")
    } else {
      setHeaderBkgColor("bg-transparent")
    }
  }

  useEffect(() => {
    const scrollContainer = scrollRef.current

    scrollContainer.addEventListener("scroll", handleScroll)

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll)
    }
  }, [scrollRef, contextState.darkMode])

  useEffect(() => {
    if (contextState.authenticated === false) {
      navigate(AppRoutes.SignIn.path, {
        state: { targetPath: `${location.pathname}${location.search}` },
      })
    }
  }, [contextState.authenticated])

  useEffect(() => {
    if (!contextState.authenticated) return
    if (
      contextState.employee &&
      Object.keys(contextState.employee).length === 0
    ) {
      getRequest(
        "/user/employee/info",
        () => {},
        (response) => {
          updateContextState({
            ...response.user_auth_params,
          })
        }
      )
    }
  }, [contextState.authenticated])

  const toggleSidebar = () => setShowSidebar(!showSidebar)

  useEffect(() => {
    if (leftNavbarScrollRef.current) {
      leftNavbarScrollRef.current.scrollTop =
        contextState.leftNavbarScrollPosition
    }
  }, [contextState.leftNavbarScrollPosition])

  const handleLeftNavbarScroll = () => {
    if (leftNavbarScrollRef.current) {
      // Clear any existing timeout for scroll end detection
      if (leftNavbarScrollTimeoutRef.current) {
        clearTimeout(leftNavbarScrollTimeoutRef.current)
      }

      // Save the scroll position after the user stops scrolling (e.g., after 500ms)
      leftNavbarScrollTimeoutRef.current = setTimeout(() => {
        updateContextState({
          leftNavbarScrollPosition: leftNavbarScrollRef.current.scrollTop,
        })
      }, 750)
    }
  }

  const getRequest = async (
    endpoint,
    loadingCallback,
    onSuccess,
    alert = false
  ) => {
    if (loadingCallback) loadingCallback(true)

    const { success, response } = await apiRequest.get(`${endpoint}`)
    if (success) {
      await onSuccess(response)
      if (alert) {
        showToast({
          message: response.message,
          success: true,
        })
      }
    } else if (endpoint !== "/user/signout") {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      })
    }
    if (loadingCallback) loadingCallback(false)
  }

  const putRequest = async (
    endpoint,
    loadingCallback,
    onSuccess = () => {},
    vals = null,
    multipart = false,
    updateOffCanvas = true,
    successAlert = true
  ) => {
    if (updateOffCanvas) {
      closeOffCanvas()
    }
    if (loadingCallback) loadingCallback(true)
    const { success, response } = await apiRequest.put(
      endpoint,
      vals,
      multipart
    )
    if (success) {
      await onSuccess(response)
      if (successAlert) {
        showToast({
          message: response.message,
          success: true,
        })
      }
    } else {
      if (updateOffCanvas) {
        restoreOffCanvas()
      }
      showToast({
        title: "Error Updating Info",
        message: response.message,
      })
    }
    if (loadingCallback) loadingCallback(false)
  }

  const patchRequest = async (
    endpoint,
    loadingCallback,
    onSuccess = () => {},
    vals = null,
    multipart = false,
    updateOffCanvas = true,
    successAlert = true
  ) => {
    if (updateOffCanvas) {
      closeOffCanvas()
    }
    if (loadingCallback) loadingCallback(true)
    const { success, response } = await apiRequest.patch(
      endpoint,
      vals,
      multipart
    )
    if (success) {
      onSuccess(response)
      if (successAlert) {
        showToast({
          message: response.message,
          success: true,
        })
      }
    } else {
      if (updateOffCanvas) {
        restoreOffCanvas()
      }
      showToast({
        title: "Error Updating Info",
        message: response.message,
      })
    }
    if (loadingCallback) loadingCallback(false)
  }

  const postRequest = async (
    endpoint,
    loadingCallback,
    onSuccess = () => {},
    vals = null,
    multipart = false,
    updateOffCanvas = true,
    successAlert = true
  ) => {
    if (updateOffCanvas) {
      closeOffCanvas()
    }
    if (loadingCallback) loadingCallback(true)
    const { success, response } = await apiRequest.post(
      endpoint,
      vals,
      multipart
    )

    if (success) {
      onSuccess(response)
      if (successAlert) {
        showToast({
          message: response.message,
          success: true,
        })
      }
    } else {
      if (updateOffCanvas) {
        restoreOffCanvas()
      }
      showToast({
        title: "Error Updating Info",
        message: response.message,
      })
    }
    if (loadingCallback) loadingCallback(false)
  }

  const deleteRequest = async (
    endpoint,
    loadingCallback,
    onSuccess,
    alert = true
  ) => {
    if (loadingCallback) loadingCallback(true)

    const { success, response } = await apiRequest.delete(`${endpoint}`)
    if (success) {
      onSuccess(response)
      if (alert) {
        showToast({
          message: response.message,
          success: true,
        })
      }
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      })
    }
    if (loadingCallback) loadingCallback(false)
  }

  return (
    <>
      <Container
        fluid={true}
        className={"bg-primary bg-opacity-10"}
      >
        <Row className={"gap-0"}>
          <Col
            lg={2}
            className={"d-none d-lg-flex flex-column vh-100 overflow-auto g-0"}
            ref={leftNavbarScrollRef}
            onScroll={handleLeftNavbarScroll}
          >
            <LeftNavbar permissions={contextState.permissions} />
          </Col>

          <Col
            lg={10}
            ref={scrollRef}
            className="d-flex flex-column vh-100 overflow-auto g-0 z-0"
          >
            <div className="sticky-top">
              <TopNavbar
                getRequest={getRequest}
                bkgColor={headerBkgColor}
                toggleSidebar={toggleSidebar}
              />
            </div>

            <div className={"pt-3 mx-3 z-2"}>
              <ConditionalView
                condition={pageComponent !== null}
                trueView={<div>{pageComponent}</div>}
                falseView={
                  <>
                    <PageComponent
                      getRequest={getRequest}
                      putRequest={putRequest}
                      patchRequest={patchRequest}
                      postRequest={postRequest}
                      deleteRequest={deleteRequest}
                    />
                  </>
                }
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Offcanvas
        show={showSidebar}
        onHide={toggleSidebar}
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <LeftNavbar permissions={contextState.permissions} />
        </Offcanvas.Body>
      </Offcanvas>
      <AppToasts />
      <AppOffCanvas />
      <AppModal />
    </>
  )
}

export default DashboardPageContainer
