import React, { useContext, useEffect, useState } from "react"
import {
  AddItemButton,
  InfoDisplayTable,
  LoadingView,
  NoInfoCard,
} from "../../../utils/components"
import {
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { Button, Card, Col, Row, Table } from "react-bootstrap"
import Calendar from "react-calendar"
import {
  dateFormats,
  formatDate,
  formatDateInDayJs,
  formDate,
  timeDiffText,
} from "../../../utils/dateTime"
import {
  FaCalendar,
  FaCheck,
  FaDotCircle,
  FaList,
  FaMapMarkerAlt,
  FaRegCalendarAlt,
  FaRegFileAlt,
  FaTimes,
  FaUsers,
} from "react-icons/fa"
import IconWithText from "../Shared/IconWithText"
import { FaHand, FaTrashCan } from "react-icons/fa6"
import GenericForm from "../../Forms/Shared/GenericForm"
import { FormFieldTypes } from "../../FormFields"

function ExternalAppointmentsSlotsView({
  status,
  getRequest,
  calendarId,
  calendar,
  putRequest,
  patchRequest,
  deleteRequest,
  postRequest,
  loaderCallback,
}) {
  const { contextState } = useContext(AppContext)

  const { employee, darkMode } = contextState

  const { showOffCanvas } = useOffCanvas()
  const { updateContextState } = useContext(AppContext)
  const { showAppModal } = useAppModal()

  const [slots, setSlots] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [activeDateStr, setActiveDateStr] = useState("today")
  const [guests, setGuests] = useState({})

  useEffect(() => {
    setActiveDateStr(formatDate(selectedDate, dateFormats.fullDate))
  }, [selectedDate])

  const statuses = {
    created: "Created",
    pending: "Pending",
    confirmed: "Confirmed",
  }

  const getSlots = (date = null) => {
    const dateStr = date ? formDate(date, dateFormats.fullDate) : ""
    getRequest(
      `/appointment/external/slots/${calendarId}/${status}${
        date ? `?date=${dateStr}` : ""
      }`,
      setIsLoading,
      (response) => {
        // This appointments are returned for hte entire month on the date passed or the current date
        updateObjectState(setSlots, response.appointments)
        updateObjectState(setGuests, response.guests)
      }
    )
  }

  useEffect(() => {
    getSlots()
  }, [])

  const disableDateTitle = ({ date, view }) => {
    const dateStr = formDate(date, dateFormats.fullDate)
    const isEmpty = !slots[dateStr] || slots[dateStr].length === 0
    return isEmpty ? (
      ""
    ) : (
      <span>
        <br />
        <FaDotCircle size={5} />
      </span>
    )
  }

  const getDateStr = (date) => {
    return formDate(date, dateFormats.fullDate)
  }

  const removeSlotFromDay = (slot) => {
    const allSlots = { ...slots }
    allSlots[activeDateStr] = allSlots[activeDateStr].filter(
      (s) => s.id !== slot.id
    )
    setSlots(allSlots)
  }

  const rescheduleAppointment = (slot) => {
    updateContextState({
      formData: {
        ...slot,
      },
    })

    showOffCanvas({
      title: "Reschedule Appointment",
      subtitle: "Select a new date and time for the appointment",
      component: (
        <GenericForm
          doDateFormatting={false}
          formConfigs={[
            {
              valueKey: "start_datetime",
              labelName: "New Start Date and Time",
              type: FormFieldTypes.DATETIME_LOCAL,
            },
            {
              valueKey: "end_datetime",
              labelName: "New End Date and Time",
              type: FormFieldTypes.DATETIME_LOCAL,
            },
            {
              valueKey: "reschedule_reason",
              labelName: "Reason for Rescheduling",
              type: FormFieldTypes.TEXT_AREA,
            },
          ]}
          onSubmit={(data) => {
            postRequest(
              `/appointment/external/slot/${slot.id}/reschedule`,
              setIsLoading,
              (response) => {
                // update appointment slot with response.appointment
                const allSlots = { ...slots }
                const daySlots = allSlots[activeDateStr].map((s) => {
                  if (s.id === slot.id) {
                    return response.appointment
                  }
                  return s
                })
                allSlots[activeDateStr] = daySlots
                setSlots(allSlots)
              },
              data
            )
          }}
        />
      ),
    })
  }

  if (!slots) {
    return <LoadingView isLoading={true} />
  } else {
    return (
      <LoadingView
        isLoading={isLoading}
        view={
          <div>
            <Card>
              <Card.Header className={"py-4"}>
                <Card.Title>{status} Appointment Slots</Card.Title>
                <Card.Subtitle
                  as={"p"}
                  className={"mt-3"}
                >
                  Manage appointment slots here, you can confirm, reschedule or
                  cancel appointments.
                </Card.Subtitle>
              </Card.Header>
            </Card>

            <Row className={"mt-4"}>
              <Col
                xs={12}
                lg={3}
                className={"mb-4 ps-4"}
              >
                <Calendar
                  defaultDate={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date)
                    const dateStr = getDateStr(date)
                    if (!slots[dateStr]) {
                      getSlots(date)
                    }
                  }}
                  value={selectedDate}
                  className={`${
                    darkMode ? "bg-dark text-white" : "bg-white text-dark"
                  }`}
                  tileClassName={({ date, view }) => {
                    if (formDate(date) === formDate(selectedDate)) {
                      return "bg-primary text-white"
                    }
                    return "bg-body primary-hover"
                  }}
                  tileContent={disableDateTitle}
                />
              </Col>

              <Col
                xs={12}
                lg={9}
                className={"mb-4"}
              >
                {slots[activeDateStr] && slots[activeDateStr].length > 0 ? (
                  <div>
                    {slots[activeDateStr].map((slot, i) => {
                      const slotGuests = guests[slot.id] || []

                      return (
                        <Card
                          className={"mb-4"}
                          key={slot.id}
                        >
                          <Card.Header className={"py-4"}>
                            <Card.Title>{slot.title || "No title"}</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={6}>
                                <IconWithText
                                  Icon={FaRegCalendarAlt}
                                  text={
                                    <span>
                                      {formatDateInDayJs(slot?.start_datetime)}
                                      <br />
                                      {`${formatDateInDayJs(
                                        slot?.start_datetime,
                                        dateFormats.time24Hour
                                      )} - ${formatDateInDayJs(
                                        slot?.end_datetime,
                                        dateFormats.time24Hour
                                      )} (${timeDiffText(
                                        slot?.start_datetime,
                                        slot?.end_datetime
                                      )})`}
                                    </span>
                                  }
                                />
                                <IconWithText
                                  Icon={FaRegFileAlt}
                                  text={
                                    <span>
                                      {slot?.description || "No description"}
                                    </span>
                                  }
                                />
                              </Col>

                              <Col md={6}>
                                <IconWithText
                                  Icon={FaMapMarkerAlt}
                                  text={
                                    <span>
                                      {slot?.location || "No location"}
                                    </span>
                                  }
                                />

                                <IconWithText
                                  Icon={FaUsers}
                                  text={
                                    <span
                                      className={
                                        slotGuests.length > 0
                                          ? "link-primary pointer text-decoration-underline"
                                          : ""
                                      }
                                      onClick={() => {
                                        if (slotGuests.length === 0) {
                                          return
                                        }
                                        showAppModal({
                                          title: "Guests",
                                          hideFooter: true,
                                          component: (
                                            <div>
                                              {slotGuests.map(
                                                (guest, index) => {
                                                  return (
                                                    <Card className={"mb-3"}>
                                                      <Card.Body
                                                        className={"py-3"}
                                                      >
                                                        <strong>Name:</strong>{" "}
                                                        {guest.full_name}
                                                        <br />
                                                        <strong>
                                                          Email:
                                                        </strong>{" "}
                                                        {guest.email}
                                                        <br />
                                                        <strong>
                                                          Phone Number:
                                                        </strong>{" "}
                                                        {guest.phone_number}
                                                      </Card.Body>
                                                    </Card>
                                                  )
                                                }
                                              )}
                                            </div>
                                          ),
                                        })
                                      }}
                                    >
                                      {slotGuests.length} guest
                                      {slotGuests.length === 1 ? "" : "s"}
                                    </span>
                                  }
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer
                            className={"d-flex justify-content-end py-4"}
                          >
                            {status === statuses.pending && (
                              <span>
                                <Button
                                  variant={"success"}
                                  className={"me-3"}
                                  size={"sm"}
                                  onClick={() => {
                                    updateContextState({
                                      formData: {
                                        location: calendar.default_location,
                                        instructions:
                                          calendar.default_instructions,
                                      },
                                    })
                                    showOffCanvas({
                                      title: "Confirm Appointment Request",
                                      subtitle:
                                        "We will send an email to the guests notifying them of the confirmation",
                                      component: (
                                        <div>
                                          <GenericForm
                                            formConfigs={[
                                              {
                                                valueKey: "location",
                                                labelName:
                                                  "Appointment Location",
                                                type: FormFieldTypes.TEXT,
                                                isRequired: true,
                                              },
                                              {
                                                valueKey: "instructions",
                                                labelName:
                                                  "Instructions for Guest",
                                                type: FormFieldTypes.TEXT_AREA,
                                                isRequired: true,
                                              },
                                            ]}
                                            onSubmit={(data) => {
                                              postRequest(
                                                `/appointment/external/slot/${slot.id}/confirm`,
                                                setIsLoading,
                                                (response) => {
                                                  removeSlotFromDay(slot)
                                                },
                                                data
                                              )
                                            }}
                                          />
                                        </div>
                                      ),
                                    })
                                  }}
                                >
                                  <FaCheck className={"me-2"} />
                                  Confirm
                                </Button>

                                <Button
                                  variant={"warning"}
                                  className={"me-3"}
                                  size={"sm"}
                                  onClick={() => {
                                    updateContextState({
                                      formData: {},
                                    })
                                    showOffCanvas({
                                      title: "Decline Appointment Request",
                                      subtitle:
                                        "We will send an email to the guests notifying them of the decline",
                                      component: (
                                        <div>
                                          <GenericForm
                                            formConfigs={[
                                              {
                                                valueKey: "decline_reason",
                                                labelName:
                                                  "Reason for Declining",
                                                type: FormFieldTypes.TEXT_AREA,
                                                isRequired: false,
                                              },
                                              {
                                                valueKey: "delete_slot",
                                                labelName:
                                                  "Also Delete this Slot?",
                                                type: FormFieldTypes.SWITCH,
                                                info: "This will delete the slot from the available appointment slots for bookings.",
                                              },
                                            ]}
                                            onSubmit={(data) => {
                                              postRequest(
                                                `/appointment/external/slot/${slot.id}/decline`,
                                                setIsLoading,
                                                (response) => {
                                                  removeSlotFromDay(slot)
                                                },
                                                data
                                              )
                                            }}
                                          />
                                        </div>
                                      ),
                                    })
                                  }}
                                >
                                  <FaTimes className={"me-2"} />
                                  Decline
                                </Button>
                              </span>
                            )}

                            {status === statuses.confirmed && (
                              <span>
                                <Button
                                  variant={"info"}
                                  className={"me-3"}
                                  size={"sm"}
                                  onClick={() => {
                                    rescheduleAppointment(slot)
                                  }}
                                >
                                  <FaCalendar className={"me-3"} />
                                  Re-schedule
                                </Button>
                                <Button
                                  variant={"danger"}
                                  size={"sm"}
                                  onClick={() => {
                                    updateContextState({
                                      formData: {},
                                    })
                                    showOffCanvas({
                                      title: "Cancel Appointment Request",
                                      subtitle:
                                        "We will send an email to the guests notifying them of the cancellation",
                                      component: (
                                        <div>
                                          <GenericForm
                                            formConfigs={[
                                              {
                                                valueKey: "cancel_reason",
                                                labelName:
                                                  "Reason for Cancelling",
                                                type: FormFieldTypes.TEXT_AREA,
                                              },
                                            ]}
                                            onSubmit={(data) => {
                                              postRequest(
                                                `/appointment/external/slot/${slot.id}/cancel`,
                                                setIsLoading,
                                                (response) => {
                                                  removeSlotFromDay(slot)
                                                },
                                                data
                                              )
                                            }}
                                          />
                                        </div>
                                      ),
                                    })
                                  }}
                                >
                                  <FaTimes className={"me-2"} />
                                  Cancel
                                </Button>
                              </span>
                            )}

                            {status === statuses.created && (
                              <Button
                                variant={"danger"}
                                size={"sm"}
                                onClick={() => {
                                  showAppModal({
                                    title: "Delete Appointment Slot",
                                    component: (
                                      <p>
                                        Are you sure you want to delete this
                                        appointment slot?
                                      </p>
                                    ),
                                    truthyFunction: () => {
                                      deleteRequest(
                                        `/appointment/external/slot/${slot.id}`,
                                        setIsLoading,
                                        (response) => {
                                          removeSlotFromDay(slot)
                                        }
                                      )
                                    },
                                  })
                                }}
                              >
                                <FaTrashCan className={"me-2"} />
                                Delete
                              </Button>
                            )}
                          </Card.Footer>
                        </Card>
                      )
                    })}
                  </div>
                ) : (
                  <NoInfoCard text={"No slots available for this date"} />
                )}
              </Col>
            </Row>
          </div>
        }
      />
    )
  }
}

export default ExternalAppointmentsSlotsView
