import React, { useContext, useEffect, useState } from "react"
import { AddItemButton, LoadingView } from "../../../utils/components"
import { toTitleCase, useOffCanvas } from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { Button, Card, Form } from "react-bootstrap"
import EmployeeTable from "../EmployeeTable"
import PageMetaTags from "../Shared/PageMetaTags"
import EmployeeSearch from "../EmployeeSearch"
import { FaPlus, FaSearch } from "react-icons/fa"
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext"
import OrganizationsTable from "../Organization/OrganizationsTable"
import OrganizationSearch from "../Organization/OrganizationSearch"

function StaffOrgEmpControls({
  rootOrganization,
  getRequest,
  patchRequest,
  mainLoaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { updateContextState } = useContext(AppContext)
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext)

  const [organizations, setOrganizations] = useState([])
  const [calendarEmployees, setCalendarEmployees] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const ORG_CONTROL_FIELDS = [
    "has_appointments_access",
    "children_have_appointments_access",
  ]
  const EMPLOYEE_CONTROL_FIELDS = ["has_appointments_calendar_access"]
  const CALENDAR_CONTROL_FIELDS = ["can_be_external"]

  useEffect(() => {
    getRequest(
      `/staff/organization/${rootOrganization.id}/controls`,
      setIsLoading,
      (response) => {
        setCalendarEmployees(response.calendar_employees)
        setOrganizations(response.organizations)
      }
    )
  }, [])

  const getEmpExtraColumns = () => {
    const columns = [
      { columnName: "Organization", key: "primary_organization_name" },
    ]

    EMPLOYEE_CONTROL_FIELDS.forEach((field) => {
      columns.push({
        columnName: toTitleCase(field),
        renderComponent: (employee) => {
          return (
            <div>
              <Form.Switch
                name={field}
                value={employee[field] || false}
                checked={employee[field] === true}
                onChange={(e) => {
                  const checked = e.target.checked
                  updateEmployeeControls({ [field]: checked }, employee.id)
                }}
              />
            </div>
          )
        },
      })
    })

    CALENDAR_CONTROL_FIELDS.forEach((field) => {
      columns.push({
        columnName: `Appt. Cal. ${toTitleCase(field)}`,
        renderComponent: (employee) => {
          if (!employee.calendar) {
            return <div></div>
          }
          return (
            <div>
              <Form.Switch
                name={field}
                value={employee.calendar[field] || false}
                checked={employee.calendar[field] === true}
                onChange={(e) => {
                  const checked = e.target.checked
                  calendarEmployeesColumns(employee.id, employee.calendar.id, {
                    [field]: checked,
                  })
                }}
              />
            </div>
          )
        },
      })
    })

    return columns
  }

  const updateOrganizationControl = (organizationData, organizationId) => {
    patchRequest(
      `/staff/organization/${organizationId}/controls`,
      null,
      (response) => {
        setOrganizations(
          organizations.map((org) =>
            org.id === organizationId ? { ...org, ...organizationData } : org
          )
        )
      },
      organizationData,
      false,
      false,
      false
    )
  }

  const updateEmployeeControls = (employeeData, employeeId) => {
    patchRequest(
      `/staff/employee/${employeeId}/controls`,
      null,
      (response) => {
        setCalendarEmployees(
          calendarEmployees.map((emp) =>
            emp.id === employeeId
              ? {
                  ...emp,
                  ...employeeData,
                  calendar: response.calendar,
                }
              : emp
          )
        )
      },
      employeeData,
      false,
      false,
      false
    )
  }

  const calendarEmployeesColumns = (employeeId, calendarId, calendarData) => {
    patchRequest(
      `/staff/appointments-calendar/${calendarId}/controls`,
      null,
      (response) => {
        // Each emp has a .calendar object, update the calendar with calendarData
        setCalendarEmployees(
          calendarEmployees.map((emp) =>
            emp.id === employeeId
              ? {
                  ...emp,
                  calendar: {
                    ...emp.calendar,
                    ...calendarData,
                  },
                }
              : emp
          )
        )
      },
      calendarData,
      false,
      false,
      false
    )
  }

  return (
    <div>
      <LoadingView
        isLoading={isLoading}
        view={
          <div>
            <Card className={"mb-4"}>
              <Card.Header className={"py-4"}>
                <div className={"d-flex justify-content-between"}>
                  <div>
                    <Card.Title>Organizations Controls</Card.Title>
                    <Card.Subtitle
                      as={"p"}
                      className={"mt-3"}
                    >
                      Control feature access for the child and root
                      organizations
                    </Card.Subtitle>
                  </div>

                  <Button
                    variant={"outline-primary"}
                    onClick={() => {
                      showOffCanvas({
                        title: "Search Organizations",
                        subtitle:
                          "Search for an organization to manage their access controls",
                        component: (
                          <div>
                            <OrganizationSearch
                              searchEndpoint={`/staff/organization/${rootOrganization.id}/search`}
                              onResultClick={(organization) => {
                                closeOffCanvas()
                                // add to organizations if not already there
                                if (
                                  !organizations.find(
                                    (org) => org.id === organization.id
                                  )
                                ) {
                                  setOrganizations([
                                    ...organizations,
                                    organization,
                                  ])
                                }
                              }}
                            />
                          </div>
                        ),
                      })
                    }}
                  >
                    <FaPlus className={"me-2"} />
                    Add Org
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <OrganizationsTable
                  organizations={organizations}
                  noOrganizationsMsg={
                    "No organizations found with access controls"
                  }
                  extraColumns={ORG_CONTROL_FIELDS.map((field) => ({
                    columnName: toTitleCase(field),
                    renderComponent: (organization) => {
                      return (
                        <div>
                          <Form.Switch
                            name={field}
                            value={organization[field] || false}
                            checked={organization[field] === true}
                            onChange={(e) => {
                              const checked = e.target.checked
                              updateOrganizationControl(
                                { [field]: checked },
                                organization.id
                              )
                            }}
                          />
                        </div>
                      )
                    },
                  }))}
                />
              </Card.Body>
            </Card>

            <Card>
              <Card.Header className={"py-4"}>
                <div className={"d-flex justify-content-between"}>
                  <div>
                    <Card.Title>Employees Controls</Card.Title>
                    <Card.Subtitle
                      as={"p"}
                      className={"mt-3"}
                    >
                      Control access for the universo of employees in the root
                      organization
                    </Card.Subtitle>
                  </div>

                  <Button
                    variant={"outline-primary"}
                    onClick={() => {
                      showOffCanvas({
                        title: "Search Employee",
                        subtitle:
                          "Search for an employee to add controls for them",
                        component: (
                          <div>
                            <EmployeeSearch
                              searchEndpoint={`/staff/organization/${rootOrganization.id}/search-employees`}
                              onResultClick={(employee) => {
                                closeOffCanvas()
                                // add to calendar employees if not already there
                                if (
                                  !calendarEmployees.find(
                                    (emp) => emp.id === employee.id
                                  )
                                ) {
                                  setCalendarEmployees([
                                    ...calendarEmployees,
                                    employee,
                                  ])
                                }
                              }}
                            />
                          </div>
                        ),
                      })
                    }}
                  >
                    <FaPlus className={"me-2"} /> Add Employee
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <EmployeeTable
                  pagingInfo={null}
                  employees={calendarEmployees}
                  noEmployeesMsg={
                    "No employees found with appointments calendar access"
                  }
                  minimal={true}
                  extraColumns={getEmpExtraColumns()}
                />
              </Card.Body>
            </Card>
          </div>
        }
      />
    </div>
  )
}

export default StaffOrgEmpControls
