import { appConfigs } from "../configs"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const timePeriodTypes = ["Daily", "Weekly", "Monthly", "Yearly", "All Time"]

export const AppConstants = {
  companyName: "humnce LLC",
  supportEmail: "support@humnce.com",
  authTokenKey: "authToken",
  defaultProfileImage:
    "https://humnce-public.s3.us-west-2.amazonaws.com/images/default-profile-image.jpg",
  defaultImage:
    "https://humnce-public.s3.us-west-2.amazonaws.com/images/no-image-pic.jpg",
  primaryColor: "#5e17eb",
  initPaging: {
    current_page: 1,
    per_page: 10,
    total_pages: 1,
    has_next: false,
    next_page: 1,
  },
  months,
  chartColors: [
    "#5e17eb", // Primary Color (Blue-Purple)
    "#4ea1f5", // Bright Sky Blue
    "#32a852", // Lush Green
    "#f5a442", // Warm Orange
    "#f542b5", // Vibrant Pink
    "#f54242", // Bright Red
    "#f5e142", // Sunny Yellow
    "#42f5e1", // Turquoise
    "#a442f5", // Deep Violet
    "#f5f242", // Lemon Yellow
    "#42f5b5", // Light Sea Green
    "#42a5f5", // Soft Blue
    "#a5f542", // Lime Green
    "#f5427d", // Hot Pink
    "#7d42f5", // Purple
    "#f5b042", // Orange
    "#42f57d", // Mint Green
    "#7df542", // Bright Green
    "#f57d42", // Coral
    "#f5429e", // Magenta
  ],
  timePeriodTypes,
  peopleStatsIncludeDescendantsKey: "peopleStatsIncludeDescendants",
  passwordHints: [
    "Password must be at least 8 characters long",
    "Password must contain at least one uppercase letter",
    "Password must contain at least one lowercase letter",
    "Password must contain at least one digit",
    "Password must contain at least one special character",
    "Special characters allowed are: !@#$%^&*()_+-=,.?;:'\"[]{}|",
    "Password must not contain /, \\, or <>",
  ],
  activeAppointmentsCalendarIdKey: "activeAppointmentsCalendarIdKey",
}
