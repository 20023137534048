import humnceWhiteColorOutlineLogo from "../src/assets/logos/white-color-outline.png"
import goslLogo from "../src/assets/gosl/logos/gosl-logo.png"
import GoSLIndexPage from "./pages/gosl/GoslIndexPage"

const webAddress = "humnce.com"
const env = process.env.REACT_APP_ENV

const sharedConfigs = {
  appName: "humnce",
  googleMapsApiKey: "AIzaSyARJ4EeZ---l95ztYsu7hdiO5__qE0DHL8",
  isDev: false,
  isStg: false,
  isProd: false,
  primaryColor: "#5e17eb",
  primaryLogo: humnceWhiteColorOutlineLogo,
  companyName: "humnce LLC",
  darkColor: "#000000",
  lightColor: "#ffffff",

  // apiHostname: "http://localhost:5000",
  apiHostname:
    "https://75db-2601-642-4e82-9d50-e41d-99e0-5514-c296.ngrok-free.app",
  // apiHostname: "http://10.0.0.104:5000",
  // feHostname: "http://10.0.0.104:3000",
  feHostname: "http://localhost:3000",
}

const configs = {
  dev: {
    ...sharedConfigs,
    ...{
      isDev: true,
    },
  },
  stg: {
    ...sharedConfigs,
    ...{
      isStg: true,
      apiHostname: `https://stg-api.${webAddress}`,
      feHostname: `https://stg.${webAddress}`,
    },
  },
  prod: {
    ...sharedConfigs,
    ...{
      isProd: true,
      apiHostname: `https://api.${webAddress}`,
      feHostname: `https://${webAddress}`,
    },
  },
  "gosl-prod": {
    ...sharedConfigs,
    ...{
      IndexPage: GoSLIndexPage,
      isProd: true,
      apiHostname: `https://gosl-api.${webAddress}`,
      feHostname: `https://gosl.${webAddress}`,
      primaryColor: "#041abc",
      primaryLogo: goslLogo,
      appName: "GoSL HR Hub",
      companyName: "Government of Sierra Leone",
    },
  },
}

function processConfigs() {
  let cfg = configs[env]
  cfg.sharingUrl = `${cfg.apiHostname}/share/appointment/booking`
  return cfg
}

export const appConfigs = processConfigs()
