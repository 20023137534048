import { FormFieldTypes } from "../../FormFields"

const AccountingFormsConfigs = {
  account: [
    {
      valueKey: "name",
      labelName: "Account Name",
    },
    {
      valueKey: "description",
      labelName: "Account Description",
      type: FormFieldTypes.TEXT_AREA,
      isRequired: false,
    },
    {
      valueKey: "low_balance_threshold",
      labelName: "Low Balance Threshold",
      info: "When the account balance falls below this amount, you will receive a notification.",
      type: FormFieldTypes.NUMBER,
    },
  ],
  fundAccount: (companyAccounts) => {
    return [
      {
        valueKey: "amount",
        labelName: "Credit Amount",
        type: FormFieldTypes.NUMBER,
      },
      {
        valueKey: "external_transaction_id",
        labelName: "Deposit or Transfer ID",
        info: "The ID or reference number of the deposit or transfer.",
      },
      {
        valueKey: "proof_file",
        labelName: "Proof of Deposit or Transfer",
        type: FormFieldTypes.PDF,
        info: "Upload a PDF file that shows proof of the deposit or transfer.",
        maxFileMb: 5,
      },
      {
        valueKey: "company_account",
        labelName: "Deposit Account",
        info: "Choose one of our company accounts you made the deposit or transfer to.",
        type: FormFieldTypes.STRING_SELECT,
        optionsList: companyAccounts,
      },
    ]
  },
}

export default AccountingFormsConfigs
