import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../utils/components/AppContext"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import DashboardPageContainer from "./components/DashboardPageContainer"
import { LoadingView } from "../utils/components"
import PageMetaTags from "./components/Shared/PageMetaTags"
import {
  toTitleCase,
  updateObjectState,
  useOffCanvas,
} from "../utils/functions"
import { Button, Card, Tab, Tabs } from "react-bootstrap"
import AppointmentSlotSelector from "./components/Appointment/AppointmentSlotSelector"
import OrganizationSearch from "./components/Organization/OrganizationSearch"
import { FaEdit, FaPlus } from "react-icons/fa"
import GenericForm from "./Forms/Shared/GenericForm"
import { FormFieldTypes } from "./FormFields"
import AppBreadCrumb from "./components/Shared/AppBreadCrumb"
import { AppRoutes } from "../utils/appRoutes"
import ExternalAppointmentsSlotsView from "./components/Appointment/ExternalAppointmentsSlotsView"
import CopyWithTooltip from "./components/Shared/CopyWithTooltip"
import { appConfigs } from "../configs"

function ManageExternamAppointments({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { showOffCanvas } = useOffCanvas()

  const { contextState, updateContextState } = useContext(AppContext)
  const { employee, labelsOverride } = contextState

  const navigate = useNavigate()

  const { calendarId } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"))
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [calendar, setCalendar] = useState({})
  const [appTabsRendered, setAppTabsRendered] = useState({
    confirmed: false,
    created: false,
    pending_confirmation: false,
  })

  const activateTab = (key) => {
    key = key || "details_and_slots"
    setActiveTab(key)
    updateQueryParam("activeTab", key)

    updateObjectState(setAppTabsRendered, {
      [key]: true,
    })
  }

  useEffect(() => {
    activateTab(searchParams.get("activeTab"))
  }, [searchParams])

  useEffect(() => {
    getRequest(
      `/appointment/external/${calendarId}`,
      setIsLoading,
      (response) => {
        setCalendar(response.calendar)
      }
    )
  }, [])

  const editExternalDetails = () => {
    updateContextState({
      formData: {
        ...calendar,
      },
    })

    showOffCanvas({
      title: "Edit External Details",
      subtitle:
        "Edit the details people who want to book an appointment will see",
      component: (
        <div>
          <GenericForm
            formConfigs={[
              {
                valueKey: "external_name",
                labelName: "Calendar Title",
                type: FormFieldTypes.TEXT,
              },
              {
                valueKey: "external_description",
                labelName: "Calendar Description",
                type: FormFieldTypes.TEXT_AREA,
              },
              {
                valueKey: "default_location",
                labelName: "Default Appointment Location",
                type: FormFieldTypes.TEXT,
                info: "This location will be added to appointments by default when confirming them. You can always change it on the appointment or here.",
              },
              {
                valueKey: "default_instructions",
                labelName: "Default Guests Instructions",
                type: FormFieldTypes.TEXT_AREA,
                info: "These instructions will be for the guests and it will be added to appointments by default when confirming them. You can always change it on the appointment or here.",
              },
              {
                valueKey: "display_organization_info_externally",
                labelName: "Show Organization Info",
                type: FormFieldTypes.SWITCH,
                info: "This will display your organization's logo, name and description on the external booking page.",
              },
            ]}
            onSubmit={(data) => {
              patchRequest(
                `/appointment/external/${calendarId}`,
                setIsLoading,
                (response) => {
                  setCalendar(response.calendar)
                },
                data
              )
            }}
          />
        </div>
      ),
    })
  }

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`External Appts. | ${toTitleCase(activeTab)}`} />

          {calendar.id && (
            <AppBreadCrumb
              items={[
                {
                  text: `< ${
                    employee.id === calendar?.employee?.id
                      ? "Your"
                      : `${calendar?.employee?.first_name} ${calendar?.employee?.last_name}'s`
                  } Calendar`,
                  path: AppRoutes.Appointments.path,
                },
              ]}
            />
          )}

          <Tabs
            activeKey={activeTab}
            className={"mb-2 pb-3"}
            variant={"underline"}
            onSelect={(key) => {
              activateTab(key)
            }}
          >
            <Tab
              eventKey="details_and_slots"
              title="Details & Slots"
            >
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    {!calendar.external_name && !calendar.external_description && (
                      <Card
                        body={true}
                        className={
                          "py-4 mb-4 justify-content-center text-center"
                        }
                      >
                        <h6>
                          Please add external details for this calendar. The
                          details you add here is what people who want to book
                          an appointment will see.
                        </h6>
                        <Button
                          variant={"primary"}
                          className={"mt-4"}
                          onClick={() => {
                            editExternalDetails()
                          }}
                        >
                          <FaPlus className={"me-2"} /> Add Details
                        </Button>
                      </Card>
                    )}

                    {calendar.external_name && calendar.external_description && (
                      <div>
                        <Card className={"mb-5"}>
                          <Card.Header className={"py-4"}>
                            <div className={"d-flex justify-content-between"}>
                              <div>
                                <h2 className={"mb-3"}>External Details</h2>
                                <Card.Title>
                                  {calendar.external_name}
                                </Card.Title>
                                <Card.Subtitle
                                  as={"p"}
                                  className={"mt-3"}
                                >
                                  {calendar.external_description}
                                </Card.Subtitle>
                              </div>

                              <div>
                                <Button
                                  variant={"outline-primary"}
                                  onClick={() => {
                                    editExternalDetails()
                                  }}
                                >
                                  <FaEdit className={"me-2"} />
                                  Edit External Details
                                </Button>
                              </div>
                            </div>
                          </Card.Header>
                          <Card.Body className={"py-4"}>
                            <h4>Calendar Link</h4>
                            <p className={"mb-3"}>
                              Share this link with people you want to book an
                              appointment with you
                            </p>
                            <p>
                              {`${appConfigs.sharingUrl}/${calendar.id}`}
                              <CopyWithTooltip
                                text={`${appConfigs.sharingUrl}/${calendar.id}`}
                              />
                            </p>
                          </Card.Body>
                        </Card>
                        <AppointmentSlotSelector
                          calendarId={calendarId}
                          putRequest={putRequest}
                        />
                      </div>
                    )}
                  </>
                }
              />
            </Tab>

            <Tab
              eventKey="pending_confirmation"
              title="Pending Confirmation"
            >
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    {appTabsRendered.pending_confirmation && (
                      <ExternalAppointmentsSlotsView
                        status={"Pending"}
                        getRequest={getRequest}
                        calendarId={calendarId}
                        calendar={calendar}
                        putRequest={putRequest}
                        postRequest={postRequest}
                        patchRequest={patchRequest}
                        deleteRequest={deleteRequest}
                        loaderCallback={setShowSecondLoader}
                      />
                    )}
                  </>
                }
              />
            </Tab>

            <Tab
              eventKey="confirmed"
              title="Confirmed"
            >
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    {appTabsRendered.confirmed && (
                      <ExternalAppointmentsSlotsView
                        status={"Confirmed"}
                        getRequest={getRequest}
                        calendarId={calendarId}
                        calendar={calendar}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        postRequest={postRequest}
                        deleteRequest={deleteRequest}
                        loaderCallback={setShowSecondLoader}
                      />
                    )}
                  </>
                }
              />
            </Tab>
            <Tab
              eventKey="created"
              title="Created"
            >
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    {appTabsRendered.created && (
                      <ExternalAppointmentsSlotsView
                        status={"Created"}
                        getRequest={getRequest}
                        calendarId={calendarId}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        postRequest={postRequest}
                        deleteRequest={deleteRequest}
                        loaderCallback={setShowSecondLoader}
                      />
                    )}
                  </>
                }
              />
            </Tab>
          </Tabs>
        </div>
      }
    />
  )
}

function ManageExternamAppointmentsPage() {
  return <DashboardPageContainer PageComponent={ManageExternamAppointments} />
}

export default ManageExternamAppointmentsPage
