import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { Form, InputGroup, ListGroup, Spinner } from "react-bootstrap"
import { updateObjectState, useToast } from "../../utils/functions"
import { AppContext } from "../../utils/components/AppContext"
import { apiRequest } from "../../utils/apiRequests"
import { FaSearch } from "react-icons/fa"

function EmployeeSearch({
  onResultClick,
  onGetResults,
  returnResults = false,
  searchEndpoint = null,
}) {
  const { showToast } = useToast()
  // const { contextState, updateContextState } = useContext(AppContext);

  const [searchTerm, setSearchTerm] = useState("")
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getSearchResults = async () => {
      if (searchTerm.length >= 3) {
        setIsLoading(true)
        const { success, response } = await apiRequest.get(
          `${searchEndpoint || "/employee/search"}?search-term=${searchTerm}`
        )
        if (success) {
          if (onGetResults) {
            onGetResults(response.employees)
          } else {
            setResults(response.employees)
          }
        } else {
          showToast({
            title: "Error Getting Search Results",
            message: response.message,
          })
        }
        setIsLoading(false)
      } else {
        setResults([])
      }
    }

    const timeoutId = setTimeout(getSearchResults, 500)
    return () => clearTimeout(timeoutId)
  }, [searchTerm])

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value)
  }

  return (
    <div>
      <div className={"mb-4"}>
        <div className={"mb-2"}>
          <small>Search employees by Name, E-mail or Employee Number</small>
        </div>
        <InputGroup>
          <InputGroup.Text>
            <FaSearch />
          </InputGroup.Text>
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder={"Enter 3 or more characters to search"}
          />
        </InputGroup>
      </div>

      {isLoading && (
        <Spinner
          animation="border"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {!isLoading && !returnResults && (
        <ListGroup className={results.length > 0 ? "mb-4" : ""}>
          {results.map((employee, i) => (
            <ListGroup.Item
              key={i}
              action
              onClick={(event) => {
                event.preventDefault()
                onResultClick(employee)
                setResults([])
                setSearchTerm("")
              }}
            >
              <div className="d-flex align-items-center">
                <img
                  src={employee.profile_image}
                  alt={employee.full_name}
                  width={40}
                  height={40}
                  className="rounded-circle me-2"
                />
                <div className={"ms-2"}>
                  {employee.full_name} ({employee.employee_number})
                  <div className="small">{employee?.position?.title}</div>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  )
}

export default EmployeeSearch
