import dayjs from "dayjs"
import { type } from "@testing-library/user-event/dist/type"

var utc = require("dayjs/plugin/utc")
var timezone = require("dayjs/plugin/timezone")
var duration = require("dayjs/plugin/duration")
var isoWeek = require("dayjs/plugin/isoWeek")

dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isoWeek)

dayjs.tz.setDefault("UTC")

export function parseDate(dateString) {
  return dayjs(dateString)
}

export const dateFormats = {
  fullDate: "YYYY-MM-DD", // e.g. 2023-09-09
  fullDate12Hr: "YYYY-MM-DD hh:mm A", // e.g. 2023-09-09 3:30 PM
  fullDate24Hr: "YYYY-MM-DD HH:mm", // e.g. 2023-09-09 15:30
  formFieldullDate24Hr: "YYYY-MM-DDTHH:mm", // e.g. 2023-09-09T15:30

  singleDigitMonth: "YYYY-M-DD", // e.g. 2023-9-09
  singleDigitDay: "YYYY-MM-D", // e.g. 2023-09-9
  singleDigitMonthDay: "YYYY-M-D", // e.g. 2023-9-9
  fullDateTime: "YYYY-MM-DD HH:mm:ss", // e.g. 2023-09-09 15:30:00
  monthDayYear: "MM/DD/YYYY", // e.g. 09/09/2023
  singleDigitMonthDaySlash: "M/D/YYYY", // e.g. 9/9/2023
  dayMonthYear: "DD/MM/YYYY", // e.g. 09/09/2023
  singleDigitDayMonthSlash: "D/M/YYYY", // e.g. 9/9/2023
  time12Hour: "hh:mm A", // e.g. 03:30 PM
  time24Hour: "HH:mm", // e.g. 15:30
  monthNameDayYear: "MMMM DD, YYYY", // e.g. September 09, 2023
  dayNameMonthDayYear: "dddd, MMMM DD, YYYY", // e.g. Saturday, September 09, 2023

  shortMonthFullDayYear: "MMM DD, YYYY", // e.g. Sep 09, 2023

  fullMonthShortDayYear: "MMMM D, YYYY", // e.g. September 9, 2023

  shortDayFullMonthDayYear: "ddd, MMMM DD, YYYY", // e.g. Sat, September 09, 2023

  shortDayShortMonthFullDayYear: "ddd, MMM DD, YYYY", // e.g. Sat, Sep 09, 2023
  shortDayShortMonthFullDayYearTime24Hr: "ddd, MMM DD, YYYY HH:mm", // e.g. Sat, Sep 09, 2023 15:30
  shortDayShortMonthFullDayYearTime12Hr: "ddd, MMM DD, YYYY hh:mm A", // e.g. Sat, Sep 09, 2023 3:30 PM

  shortDate: "M/D/YY", // e.g. 9/9/23
  shortDateTime: "M/D/YY h:mm A", // e.g. 9/9/23 3:30 PM
  shortDateTime24Hr: "M/D/YY HH:MM", // e.g. 9/9/23 15:30
  shortDateTime24HrSecs: "D/M/YY HH:mm:ss", // e.g. 9/9/23 15:30:30

  daySingleDigitDMY12Hr: "ddd, D/M/YYYY h:mm A", // e.g. Sat, 9/9/2023 3:30 PM
}

export function getUTCDateTime(dateString = null) {
  if (!dateString) {
    return dayjs().utc()
  }
  return dayjs.utc(dateString)
}

export function formatDate(
  dateString,
  format = dateFormats.shortDayShortMonthFullDayYear,
  forceUTC = true
) {
  if (!dateString) return "N/A"
  if (forceUTC) {
    return getUTCDateTime(dateString).format(format)
  } else {
    return dayjs(dateString).format(format)
  }
}

export const stringToDateTime = (dateString, stringFormat) => {
  return dayjs(dateString, stringFormat).utc()
}

export function formDate(dateString, format = "YYYY-MM-DD") {
  return dayjs(dateString).format(format)
}
export function DateToDayJs(date) {
  return dayjs(`${date}`)
}

export function time24Hrs(dateString) {
  return getUTCDateTime(dateString).format(dateFormats.time24Hour)
}

export function time12Hrs(dateString) {
  return getUTCDateTime(dateString).format(dateFormats.time12Hour)
}

export const isPast = (date, startOfDay = true) => {
  const dt = getUTCDateTime(date)
  let compareDate
  if (startOfDay) {
    compareDate = getUTCDateTime().startOf("day")
  } else {
    compareDate = getUTCDateTime()
  }

  return dt.isBefore(compareDate)
}

export function addDays(dateString, days) {
  return dayjs(dateString).add(days, "day").format("YYYY-MM-DD")
}

export function subtractDays(dateString, days) {
  return dayjs(dateString).subtract(days, "day").format("YYYY-MM-DD")
}

export function differenceInDays(startDate, endDate) {
  return dayjs(endDate).diff(dayjs(startDate), "day")
}

export function isBefore(date1, date2) {
  return dayjs(date1).isBefore(dayjs(date2))
}

export function isAfter(date1, date2) {
  return dayjs(date1).isAfter(dayjs(date2))
}
export function isoWeekday(date) {
  return date.day() ? date.day() : 7
}

export function isSame(date1, date2) {
  return dayjs(date1).isSame(dayjs(date2))
}

export function hrsMinsSecsDiff(
  startDateString,
  endDateString,
  counter = false,
  returnString = false
) {
  let start = getUTCDateTime(startDateString)
  let end = getUTCDateTime(endDateString)

  if (counter && end.isBefore(start)) {
    end = end.add(1, "day")
  }

  const diff = end.diff(start, "milliseconds")
  const formattedDiff = dayjs.duration(diff)

  const hours = formattedDiff.hours()
  const minutes = formattedDiff.minutes()
  const seconds = formattedDiff.seconds()

  if (returnString) {
    return `${hours}h ${minutes}m ${seconds}s`
  }
  return [hours, minutes, seconds]
}

export function timeDiffCounter(time1, time2, callback) {
  if (!time1 && !time2) return "0h 0m 0s"

  const updateCallback = (hours, minutes, seconds) => {
    callback(`${hours}h ${minutes}m ${seconds}s`)
  }

  // If there is no end time, update the difference every second
  if (!time2) {
    const intervalId = setInterval(() => {
      const [hours, minutes, seconds] = hrsMinsSecsDiff(time1, null, true)
      updateCallback(hours, minutes, seconds)
    }, 1000)

    // Stop updating after 1 hour
    setTimeout(() => {
      clearInterval(intervalId)
    }, 60 * 60 * 1000)
  } else {
    const [hours, minutes, seconds] = hrsMinsSecsDiff(time1, time2)
    updateCallback(hours, minutes, seconds)
  }

  // Return the initial difference in hours, minutes, and seconds
  const [hours, minutes, seconds] = hrsMinsSecsDiff(time1, time2, true)
  return `${hours}h ${minutes}m ${seconds}s`
}

// function to get start of day
export function startOfDay(date) {
  if (date instanceof dayjs) {
    return date.startOf("day")
  }
  return getUTCDateTime(date).startOf("day")
}

// function to get end of day
export function endOfDay(date) {
  if (date instanceof dayjs) {
    return date.endOf("day")
  }
  return getUTCDateTime(date).endOf("day")
}

export function formatDateStrings(obj, excludeKeys = []) {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      formatDateStrings(obj[key])
    } else if (
      typeof obj[key] === "string" &&
      containsDateKeywords(key) &&
      !excludeKeys.includes(key)
    ) {
      const date = getUTCDateTime(obj[key])
      if (date.isValid()) {
        obj[key] = date.format("YYYY-MM-DD")
      } else {
        obj[key] = null
      }
    }
  }
  return obj
}

export function formatDateAndTimeStrings(obj, excludeKeys = []) {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      formatDateAndTimeStrings(obj[key])
    } else if (
      typeof obj[key] === "string" &&
      containsDateKeywords(key) &&
      !excludeKeys.includes(key)
    ) {
      const dateTime = getUTCDateTime(obj[key])

      if (dateTime.isValid()) {
        // Format includes date and time
        obj[key] = dateTime.format("YYYY-MM-DDTHH:mm")
      }
    }
  }
  return obj
}

function containsDateKeywords(str) {
  const keywords = ["date", "datetime", "time", "expiry"]
  return keywords.some((keyword) => str.toLowerCase().includes(keyword))
}

export function getTodayFormFormat() {
  return getUTCDateTime().format("YYYY-MM-DD")
}

export function thisWeekStart(date = null) {
  const currentDate = date ? dayjs(date) : dayjs()
  return currentDate.startOf("isoWeek")
}

export function thisWeekEnd(date = null) {
  const weekStart = thisWeekStart(date)
  return weekStart.endOf("isoWeek")
}

export function thisMonthStart(date = null) {
  const currentDate = date ? dayjs(date) : dayjs()
  return currentDate.startOf("month")
}

export function thisMonthEnd(date = null) {
  const monthStart = thisMonthStart(date)
  return monthStart.endOf("month")
}

export function thisYearStart(date = null) {
  const currentDate = date ? dayjs(date) : dayjs()
  return currentDate.startOf("year")
}

export function thisYearEnd(date = null) {
  const yearStart = thisYearStart(date)
  return yearStart.endOf("year")
}

export function weekMonthAndYearStartAndEnd(date = null) {
  const currentDate = date ? dayjs(date) : dayjs()
  return {
    weekStart: thisWeekStart(currentDate),
    weekEnd: thisWeekEnd(currentDate),
    monthStart: thisMonthStart(currentDate),
    monthEnd: thisMonthEnd(currentDate),
    yearStart: thisYearStart(currentDate),
    yearEnd: thisYearEnd(currentDate),
  }
}

export function secondsToHoursFloat(seconds, toFixed = 2) {
  if (toFixed) {
    return (seconds / 3600).toFixed(toFixed)
  }
  return seconds / 3600
}

export function periodStartEndDatetime(
  period,
  returnString = true,
  format = dateFormats.fullDateTime
) {
  // period is a string of Daily, Weekly, Monthly, Yearly or All Time
  const today = getUTCDateTime()
  // set time to start of day
  today.startOf("day")
  const wmy = weekMonthAndYearStartAndEnd(today)
  let start
  let end

  if (period === "Daily") {
    start = today
    end = today.endOf("day")
  }
  if (period === "Weekly") {
    start = wmy.weekStart
    end = wmy.weekEnd
  }
  if (period === "Monthly") {
    start = wmy.monthStart
    end = wmy.monthEnd
  }
  if (period === "Yearly") {
    start = wmy.yearStart
    end = wmy.yearEnd
  }
  if (period === "All Time") {
    // start on 01 01 2023
    start = dayjs("2023-01-01")
    end = today.endOf("day")
  }

  if (returnString) {
    return {
      start: start.format(format),
      end: end.format(format),
    }
  } else {
    return { start, end }
  }
}

export function formatDateInDayJs(
  dateString,
  format = dateFormats.shortDayShortMonthFullDayYear
) {
  if (!dateString) return "N/A"

  return getDayJsDateTime(dateString).format(format)
}
export function getDayJsDateTime(dateString = null) {
  if (!dateString) {
    return dayjs().utc()
  }
  return dayjs.utc(dateString)
}

export function timeDiffText(startDateString, endDateString) {
  let start = getDayJsDateTime(startDateString)
  let end = getDayJsDateTime(endDateString)

  const diff = end.diff(start)
  const dur = dayjs.duration(diff)

  let formattedDuration = []
  if (dur.hours() > 0) {
    formattedDuration.push(
      dur.hours() + (dur.hours() === 1 ? " hour" : " hours")
    )
  }
  if (dur.minutes() > 0) {
    formattedDuration.push(
      dur.minutes() + (dur.minutes() === 1 ? " minute" : " minutes")
    )
  }

  return formattedDuration.join(" and ")
}

export const getToday = () => {
  return getDayJsDateTime().startOf("day")
}

export const getNowPlusMins = ({
  minutesInterval = 15,
  returnNativeDate = true,
} = {}) => {
  // Get the current time and add the minutesInterval rounded
  let updatedDateTime

  if (minutesInterval === 0) {
    updatedDateTime = getDayJsDateTime()
  } else {
    updatedDateTime = getDayJsDateTime().add(
      minutesInterval - (getDayJsDateTime().minute() % minutesInterval),
      "minutes"
    )
  }

  return returnNativeDate ? updatedDateTime.toDate() : updatedDateTime
}
export const getNearest15Minutes = () => {
  // Get the current time using Day.js
  const now = dayjs()
  // Get the current minutes
  const minutes = now.minute()
  // Calculate the rounded minutes to the nearest 15
  const roundedMinutes = Math.ceil(minutes / 15) * 15
  // Create a new Day.js object for the nearest quarter hour
  const nearestQuarterHour = now.minute(roundedMinutes).second(0).millisecond(0)

  return nearestQuarterHour.toDate() // Return as a JavaScript Date object
}

/**
 * Adds a specified number of minutes to a given time in "HH:mm" format.
 *
 * @param {string} time - The time string in "HH:mm" format.
 * @param {number} minutesToAdd - The number of minutes to add.
 * @returns {string|null} - The new time in "HH:mm" format or null if input is invalid.
 */
export const addMinutes = (time, minutesToAdd) => {
  // Validate inputs
  if (!isValidTimeString(time)) {
    console.error("Invalid time provided:", time)
    return null
  }
  const [hours, minutes] = time.split(":").map(Number)
  // Create a Day.js object and add minutes
  const updatedTime = dayjs()
    .set("hour", hours)
    .set("minute", minutes)
    .add(minutesToAdd, "minute")
  // Return the formatted time in "HH:mm" format
  return updatedTime.format("HH:mm")
}
/**
 * Validates if the input string is in the "HH:mm" format.
 *
 * @param {string} time - The time string to validate.
 * @returns {boolean} - True if valid, false otherwise.
 */
const isValidTimeString = (time) => {
  if (typeof time !== "string") return false

  const timeParts = time.split(":")
  return (
    timeParts.length === 2 &&
    !isNaN(timeParts[0]) &&
    !isNaN(timeParts[1]) &&
    timeParts[0].length === 2 &&
    timeParts[1].length === 2
  ) // Ensure both parts are two digits
}

export const combineDateTime = (date, time) => {
  if (!date || !time) return null
  return `${date}T${time}`
}

export const dateStringgToLocaleMidnight = (dateString) => {
  return getDayJsDateTime(dateString).hour(0).minute(0).second(0).millisecond(0)
}
