import React, { useContext, useEffect, useState } from "react"
import { LoadingView } from "../utils/components"
import DashboardPageContainer from "./components/DashboardPageContainer"
import {
  updateObjectState,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../utils/functions"
import { AppContext } from "../utils/components/AppContext"
import { useNavigate, useParams } from "react-router-dom"
import BookingSlotSelector from "./components/Appointment/BookingSlotSelector"
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Row,
} from "react-bootstrap"
import AppToasts from "../utils/components/AppToasts"
import { dateFormats, formatDate } from "../utils/dateTime"
import GenericForm from "./Forms/Shared/GenericForm"
import { FormFieldTypes } from "./FormFields"
import { FaCheckCircle, FaChevronUp, FaUserPlus } from "react-icons/fa"
import AppModal from "../utils/components/AppModal"
import { apiRequest } from "../utils/apiRequests"
import { FaTrashCan } from "react-icons/fa6"
import { OrganizationCard } from "./components/OrganizationCard"

function ExternalAppointmentBookingPage() {
  const { contextState, updateContextState } = useContext(AppContext)
  const { permissions } = contextState
  const { showOffCanvas } = useOffCanvas()
  const { showAppModal, closeModal } = useAppModal()
  const { showToast } = useToast()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [calendar, setCalendar] = useState({})
  const [activeView, setActiveView] = useState(1)
  const [slot, setSlot] = useState({})
  const [data, setData] = useState({})
  const [guests, setGuests] = useState([])
  const [organization, setOrganization] = useState(null)

  const { calendarId } = useParams()

  useEffect(() => {
    updateContextState({
      formData: {
        phone_number: "+232",
      },
    })
  }, [])

  const GuestsForm = () => {
    return (
      <GenericForm
        formConfigs={[
          {
            valueKey: "full_name",
            type: FormFieldTypes.TEXT,
          },
          {
            valueKey: "email",
            type: FormFieldTypes.EMAIL,
            info: "E.g. alex@gmail.com",
          },
          {
            valueKey: "phone_number",
            type: FormFieldTypes.TEL,
            info: "E.g. +23288123456",
          },
        ]}
        completeButtonTitle={"Add Guest"}
        onSubmit={(formData) => {
          delete formData.validations
          setGuests([...guests, formData])
          closeModal()
          updateContextState({
            formData: {
              phone_number: "+232",
            },
          })
        }}
      />
    )
  }

  const submitBooking = async () => {
    setIsLoading(true)
    const { success, response } = await apiRequest.post(
      `/appointment/external/booking/${slot.id}`,
      {
        title: data.title,
        description: data.description,
        guests: guests,
      }
    )
    if (success) {
      setActiveView(3)
    } else {
      showToast({
        message: response.message,
      })
    }
    setIsLoading(false)
  }

  return (
    <div className={"bg-primary bg-opacity-10"}>
      <LoadingView
        isLoading={isLoading}
        view={
          <Container className={"py-5"}>
            {organization && (
              <div>
                <Card className="rounded-3 shadow mb-3">
                  <Card.Header className={"text-center py-5"}>
                    <img
                      src={organization.logo}
                      alt={organization.name}
                      className={"rounded-circle mx-auto"}
                      width={"auto"}
                      height={100}
                    />

                    <Card.Title className="fs-2 my-3">
                      {organization.name}
                    </Card.Title>
                    <Card.Subtitle className="mb-4">
                      {organization.description}
                    </Card.Subtitle>
                  </Card.Header>
                  <Card.Footer className={"py-4 text-center"}>
                    <h3>Appointment Booking</h3>
                  </Card.Footer>
                </Card>
              </div>
            )}
            <Card className={"mb-4"}>
              <Card.Header className={"py-4"}>{calendar.title}</Card.Header>
              <Card.Body>
                <p>{calendar.description}</p>
              </Card.Body>
            </Card>
            <Collapse in={activeView === 1}>
              <div>
                <BookingSlotSelector
                  calendarId={calendarId}
                  onLoad={(cal, org) => {
                    setCalendar(cal)
                    setOrganization(org)
                  }}
                  onComplete={(selectedSlot) => {
                    setSlot(selectedSlot)
                    setActiveView(2)
                  }}
                />
              </div>
            </Collapse>

            <Collapse in={activeView === 2}>
              <div>
                <Button
                  variant={"outline-primary"}
                  className={"mb-5 w-100"}
                  onClick={() => {
                    setActiveView(1)
                  }}
                >
                  <FaChevronUp className={"me-2"} /> Back
                </Button>
                <Row>
                  <Col lg={4}>
                    <Card
                      body={true}
                      className={
                        "mb-4 text-center py-4 border-2 border-primary"
                      }
                    >
                      <h5 className={"mb-3"}>Selected Timeslot</h5>
                      <h6 className={"mb-3"}>
                        {formatDate(slot.start_datetime)}
                      </h6>
                      <h6>
                        {formatDate(
                          slot.start_datetime,
                          dateFormats.time12Hour
                        )}{" "}
                        to{" "}
                        {formatDate(slot.end_datetime, dateFormats.time12Hour)}{" "}
                        GMT
                      </h6>
                    </Card>
                  </Col>
                  <Col lg={8}>
                    <Card className={"mb-4"}>
                      <Card.Header className={"py-4 text-center"}>
                        <Card.Title as={"h4"}>Enter Details</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <GenericForm
                          formConfigs={[
                            {
                              valueKey: "title",
                              labelName: "Title",
                              type: FormFieldTypes.TEXT,
                              info: "This can be your name or the organization name",
                            },
                            {
                              valueKey: "description",
                              labelName: "Booking Purpose",
                              type: FormFieldTypes.TEXT_AREA,
                              info: "Describe the purpose of the booking. This will help the person you are booking with understand what the meeting is about.",
                            },
                          ]}
                          stateData={data}
                          setStateData={setData}
                          useGlobalState={false}
                          showCompleteButton={false}
                        />

                        <h4 className={"mt-5"}>Appointment Guests</h4>
                        <p className={"small"}>
                          Add the details of the people you want to be notified
                          about this appointment including yourself.
                        </p>

                        <div className={"mt-4"}>
                          {guests.length === 0 && (
                            <div>
                              <GuestsForm />
                            </div>
                          )}

                          {guests.length > 0 && (
                            <div>
                              {guests.map((guest, index) => {
                                return (
                                  <Card className={"mb-3"}>
                                    <Card.Body className={"py-3"}>
                                      <strong>Name:</strong> {guest.full_name}
                                      <br />
                                      <strong>Email:</strong> {guest.email}
                                      <br />
                                      <strong>Phone Number:</strong>{" "}
                                      {guest.phone_number}
                                    </Card.Body>
                                    <Card.Footer
                                      className={
                                        "py-3 d-flex justify-content-end"
                                      }
                                    >
                                      <Button
                                        variant={"outline-danger"}
                                        onClick={() => {
                                          setGuests(
                                            guests.filter(
                                              (guest, i) => i !== index
                                            )
                                          )
                                        }}
                                      >
                                        <FaTrashCan className={"me-2"} />
                                        Remove
                                      </Button>
                                    </Card.Footer>
                                  </Card>
                                )
                              })}
                              <div className={"mt-4"}>
                                <Button
                                  variant={"outline-primary"}
                                  onClick={() => {
                                    showAppModal({
                                      title: "Enter Guest's Information",
                                      hideFooter: true,
                                      component: <GuestsForm />,
                                    })
                                  }}
                                >
                                  <FaUserPlus className={"me-2"} /> Add Another
                                  Guest
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Card>

                    {guests.length > 0 && (
                      <Button
                        variant={"primary"}
                        className={"w-100 mt-4 mb-5"}
                        disabled={
                          guests.length === 0 ||
                          !data.title ||
                          !data.description
                        }
                        onClick={() => {
                          submitBooking()
                        }}
                      >
                        Submit Appointment Request
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Collapse>

            <Collapse in={activeView === 3}>
              <Card
                body={true}
                className={"text-center py-5"}
              >
                <FaCheckCircle
                  size={100}
                  className={"text-success"}
                />
                <p className={"mt-5 mx-5"}>
                  Your appointment booking request has been submitted
                  successfully. You will receive an email when the appointment
                  is confirmed or declined. Check your spam / junk folder if you
                  don't see the email in your inbox.
                </p>

                <Alert
                  variant={"info"}
                  className={"mt-5"}
                >
                  This is not a confirmation of your appointment. All the guests
                  will receive an email when the appointment is confirmed or
                  declined.
                </Alert>
              </Card>
            </Collapse>
          </Container>
        }
      />
      {/*Need to add this here so the global toasts can be used on this page*/}
      <AppToasts />
      <AppModal />
    </div>
  )
}

export default ExternalAppointmentBookingPage
