import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../../utils/components/AppContext"
import {
  dateFormats,
  formatDate,
  formDate,
  timeDiffText,
} from "../../../utils/dateTime"
import { Button, Card, Col, Row } from "react-bootstrap"
import Calendar from "react-calendar"
import { FaDotCircle } from "react-icons/fa"
import { updateObjectState, useToast } from "../../../utils/functions"
import { LoadingView, NoInfoCard } from "../../../utils/components"
import { apiRequest } from "../../../utils/apiRequests"
import AppToasts from "../../../utils/components/AppToasts"

function BookingSlotSelector({
  getRequest,
  calendarId,
  headerText = "Choose your preferred appointment slot to start the booking process",
  onComplete,
  onLoad,
}) {
  const { contextState } = useContext(AppContext)

  const { employee, darkMode } = contextState
  const { showToast } = useToast()

  const [slots, setSlots] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [activeDateStr, setActiveDateStr] = useState("today")
  const [selectedSlot, setSelectedSlot] = useState(null)

  useEffect(() => {
    setActiveDateStr(formatDate(selectedDate, dateFormats.fullDate))
  }, [selectedDate])

  const disableDateTitle = ({ date, view }) => {
    const dateStr = formDate(date, dateFormats.fullDate)
    const isEmpty = !slots[dateStr] || slots[dateStr].length === 0
    return isEmpty ? (
      ""
    ) : (
      <span>
        <br />
        <FaDotCircle size={5} />
      </span>
    )
  }

  const getDateStr = (date) => {
    return formDate(date, dateFormats.fullDate)
  }

  const getSlots = async (date = null) => {
    const dateStr = date ? formDate(date, dateFormats.fullDate) : ""
    let appts = {}
    const endpoint = `/appointment/external/booking/${calendarId}${
      date ? `?date=${dateStr}` : ""
    }`
    let cal = {}
    let org = null

    if (getRequest) {
      getRequest(endpoint, setIsLoading, (response) => {
        // This appointments are returned for hte entire month on the date passed or the current date
        appts = response.appointments
        cal = response.calendar
        org = response.organization
      })
    } else {
      setIsLoading(true)
      const { success, response } = await apiRequest.get(endpoint)
      if (success) {
        appts = response.appointments
        cal = response.calendar
        org = response.organization
      } else {
        showToast({
          message: response.message,
        })
      }
      setIsLoading(false)
    }

    updateObjectState(setSlots, appts)
    if (onLoad) {
      onLoad(cal, org)
    }
  }

  useEffect(() => {
    getSlots()
  }, [])

  return (
    <LoadingView
      isLoading={isLoading}
      text={"Getting Appointment Slots"}
      view={
        <div>
          <h5 className={"my-5 text-center"}>{headerText}</h5>
          <Row className={"mt-4"}>
            <Col
              xs={12}
              lg={3}
              className={"mb-4"}
            >
              <Calendar
                defaultDate={selectedDate}
                onChange={(date) => {
                  setSelectedSlot(null)
                  setSelectedDate(date)
                  const dateStr = getDateStr(date)
                  if (!slots[dateStr]) {
                    getSlots(date)
                  }
                }}
                value={selectedDate}
                className={`${
                  darkMode ? "bg-dark text-white" : "bg-white text-dark"
                }`}
                tileClassName={({ date, view }) => {
                  if (formDate(date) === formDate(selectedDate)) {
                    return "bg-primary text-white"
                  }
                  return "bg-body primary-hover"
                }}
                tileContent={disableDateTitle}
              />
            </Col>

            <Col
              xs={12}
              lg={9}
              className={"mb-4"}
            >
              {slots[activeDateStr] && slots[activeDateStr].length > 0 ? (
                <Row>
                  {slots[activeDateStr].map((slot, i) => {
                    return (
                      <Col
                        md={4}
                        lg={3}
                        key={slot.id}
                      >
                        <Card
                          className={`mb-3 pointer ${
                            slot.id === selectedSlot?.id
                              ? "bg-primary text-white"
                              : "primary-hover"
                          }`}
                          onClick={() => {
                            setSelectedSlot(slot)
                          }}
                        >
                          <Card.Body className={"py-4 text-center"}>
                            <h5 className={"text-center"}>
                              {formatDate(
                                slot.start_datetime,
                                dateFormats.time12Hour
                              )}
                            </h5>
                            <p>to</p>
                            <h5 className={"text-center"}>
                              {formatDate(
                                slot.end_datetime,
                                dateFormats.time12Hour
                              )}
                            </h5>
                            <p>GMT Timezone</p>
                          </Card.Body>
                          <Card.Footer className={"py-2 text-center"}>
                            <p>
                              {timeDiffText(
                                slot?.start_datetime,
                                slot?.end_datetime
                              )}
                            </p>
                          </Card.Footer>
                        </Card>
                      </Col>
                    )
                  })}
                </Row>
              ) : (
                <div className={"text-center"}>
                  <NoInfoCard text={"No slots available for this date"} />
                </div>
              )}
            </Col>
          </Row>

          <div className={"text-center pt-5"}>
            <Button
              variant={"primary"}
              disabled={!selectedSlot}
              className={"mt-5 w-25"}
              onClick={() => {
                onComplete(selectedSlot)
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default BookingSlotSelector
